import React, { useReducer } from "react";
import HomeMobile from "mobile/HomeMobile";
import About from "pages/landing/About";
import Analytics from "pages/dashboard/Analytics";
import Contact from "pages/landing/Contact";
import Disclaimer from "pages/landing/Disclaimer";
import Economy from "pages/dashboard/Economy";
import Fun from "pages/Fun";
import Guidance from "resource/guidance/Guidance";
import IndustrySector from "pages/dashboard/IndustrySector";
import Market from "pages/dashboard/Market";
import News from "pages/dashboard/News";
import { Opportunities } from "pages/dashboard/Opportunities";
import PageNotFound from "pages/PageNotFound";
import Product from "pages/landing/Product";
import Profile from "pages/landing/Profile";
import Reports from "pages/dashboard/Reports";
import Resource from "pages/dashboard/Resource";
import Screener from "pages/dashboard/Screener";
import Support from "pages/landing/Support";
import TermsConditions from "pages/landing/TermsConditions";
import Ticker from "pages/dashboard/Ticker";
import Trade from "pages/dashboard/Trade";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import MyFinancials from "./pages/dashboard/MyFinancials";
import HomePage from "./home/HomePage";
import { initialStateApp } from "./appState/initialStateApp";
import { reducerApp } from "./appState/reducer";
import TopInvestorsList from "./analytics/topInvestors/TopInvestorsList";
import useWindowSize from "./utils/useWindowSize";
import Pricing from "home/pricing/Pricing";
import useAuthCheck from "auth/useAuthCheck";
import AppState from "AppState";
import AppLayout from "AppLayout";
import { ThemeProvider } from "@mui/material";
import theme from "style/theme";
import HomeLandingPage from "home/HomeLandingPage";
import HomePageChat from "home/HomePageChat";
import AskAI from "pages/dashboard/AskAI";
import FinanceProfessional from "finanaceProfessional/FinanceProfessional";
import FinancialProducts from "financialProducts/FinancialProducts";



export default function App() {

  const [stateApp, dispatchApp] = useReducer(reducerApp, initialStateApp);
  const windowSize = useWindowSize();

  useAuthCheck({ dispatchApp, stateApp });

  if (!stateApp.authChecked) {
    return <></>;
  }


  const ProtectedRoute = () => {
    return <>{!stateApp.isLoggedIn ? <Navigate to="/Home" /> : <Outlet />}</>;
  };

  return (
   
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        {windowSize < 0 && (
          <>
            <Route path="/" element={<HomeMobile />} />
            <Route path="*" element={<HomeMobile />}/>
          </>
        )}
        {windowSize > 0 && (
          <Route
            path="/"
            element={<AppState stateApp={stateApp} dispatchApp={dispatchApp} />}
          >
            <Route path="/" element={<AppLayout />}>
              {!stateApp.isLoggedIn && (
                <>
                  <Route path="/" element={<HomeLandingPage/>} />
                  <Route path="/Home" element={<HomeLandingPage/>} />
                </>
              )}
              <Route path="/Contact" element={<Contact />} />
              <Route path="/About" element={<About />} />
              <Route path="/Features" element={<Product />} />
              <Route path="/Product" element={<Product />} />
              <Route path="/Pricing" element={<Pricing />} />
              <Route path="/Support" element={<Support />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/Disclaimer" element={<Disclaimer />} />
              <Route path="/Terms" element={<TermsConditions />} />

              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<HomePageChat/>} />
                <Route path="/Home" element={<HomePageChat/>} />
                <Route path="/AskAI" element={<AskAI/>} />
                <Route path="/AskProfessional/:idProfessionalType?/:idProfessional?" element={<FinanceProfessional/>} />
                <Route
                  path="/FinancialProducts/:idFinancialProducts?"
                  element={<FinancialProducts/>}
                />
                <Route
                  path="/MyFinancials/:idMyFinancials?"
                  element={<MyFinancials />}
                />
                <Route path="/Trade" element={<Trade />} />
                <Route
                  path="Opportunities/:idOpportunities?"
                  element={<Opportunities />}
                />
                <Route
                  path="/:idTradeBase?/Trade/:idTrade?"
                  element={<Trade />}
                />

                <Route path="/Analytics" element={<Analytics />}>
                  <Route path="" element={<Ticker />} />
                  <Route path=":idBase?/Ticker" element={<Ticker />} />
                  <Route path=":idBase?/Market" element={<Market />} />
                  <Route
                    path=":idBase?/Industry"
                    element={<IndustrySector />}
                  />
                  <Route path=":idBase?/Economy" element={<Economy />} />
                  <Route path="Investors" element={<TopInvestorsList />} />
                </Route>

                <Route
                  path="/Screener/:idScreener?/:idTicker?"
                  element={<Screener />}
                />
                <Route
                  path="/:idNewsBase?/NewsEvents/:idNews?"
                  element={<News />}
                />
                <Route path="/Report/:idReportType?/:idReportDetails?" element={<Reports />} />

                <Route
                  path="Resource/:idType?/:idTopic?/:idQuestion?"
                  element={<Resource />}
                />
                <Route path="/Guidance" element={<Guidance />} />

                <Route path="/Fun" element={<Fun />} />
              </Route>
              <Route path="*" element={<PageNotFound />} status={404} />
            </Route>
          </Route>
        )}
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

import React, { useMemo } from 'react';
import { Button, Card, CardContent, Paper, Typography } from '@mui/material';
import { useAppInfo } from 'AppState';
import { formatNumber } from 'utils/utilGeneral';
import CircleScore from 'components/Charts/CircleScore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TextWithBorders from 'components/Text/TextWithBorders';
import { useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import MyFinancialHealthScore from './MyFinancialHealthScore';
import InfoBox from 'components/Layout/InfoBox';

export function MyFinancialHealthCard() {
    const {stateMyFinancials} = useAppInfo()
    const {financialHealthDetails,currency} = stateMyFinancials
    
    const navigate = useNavigate()
    
    // Group checks by category
    const groupedChecks = useMemo(() => {
        if (financialHealthDetails.length > 0) {
            return financialHealthDetails.reduce((groups, item) => {
                if (!groups[item.category]) {
                    groups[item.category] = [];
                }
                groups[item.category].push(item);
                return groups;
            }, {});
        } else {
            return {};
        }
    }, [financialHealthDetails]);

    return (
        <Paper>
            <MyFinancialHealthScore/>
            <CardContent>
                <TextWithBorders text={"Financial Health Details"}/>
                  
                <div style={{overflowX: 'auto'}}>
                    <table style={{width: '100%', borderCollapse: 'collapse',tableLayout:"fixed"}}>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Metric</th>
                                <th>Amount/Value</th>
                                <th>Best Practice</th>
                                <th>Check</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(groupedChecks).map(([category, items], catIndex) => (
                                <React.Fragment key={category}>
                                    {items.map((item, index) => (
                                        <tr key={index}>
                                            {index === 0 && (
                                              <td 
                                              onClick={()=>navigate(item?.link)}
                                              rowSpan={items.length} 
                                              style={{
                                                  padding: '8px', 
                                                  backgroundColor:'#f9f9f9',
                                                  position: 'relative', // To position the button absolutely within this cell
                                                  verticalAlign: "middle",
                                                  cursor:"pointer"
                                              }}
                                          >
                                              <h4 style={{fontWeight:"bold", margin: 0}}>
                                                  {category}
                                              </h4>
                                              
                                              <Button 
                                                  style={{
                                                      position: 'absolute',
                                                      top: '8px',  // Adjust to align with your desired position
                                                      right: '8px', // Adjust to align with your desired position
                                                      minWidth: 'auto',
                                                      padding: '0',
                                                  }} 
                                                  
                                              >
                                                  <ArrowOutwardIcon fontSize='small'/>
                                              </Button>
                                          </td>
                                            )}
                                            <td>{item.name}</td>
                                            <td>{item?.unit === "currency" && currency?.symbol}
                                                {formatNumber(item.amount)}
                                                {item?.unit === "percent" && "%"} 
                                                </td>
                                            <td>{item.bestPractice}</td>
                                            <td>
                                            {item.check? <CheckIcon sx={{color:"green"}}/> : 
                                            <CloseIcon sx={{color:"red"}}/>}
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </Paper>
    );
}

export default MyFinancialHealthCard;


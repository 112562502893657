import React, { useEffect, useState } from 'react';
import { useAppInfo } from 'AppState';
import Modal from 'components/Modal/Modal';
import {Button, Snackbar, Alert, Paper } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TextInput from 'components/Inputs/TextInput';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import { auth, db } from '../firebaseConfig/firebaseConfig';
import { doc, updateDoc } from "firebase/firestore"; 
import { updateProfile } from 'firebase/auth';
import OnboardObjectives from './OnboardObjectives';
import Logo from '../logo/Logo';
import Terms from '../home/terms/Terms';
import useUpdateOnboarding from 'data/user/onBoarding/useUpdateOnboarding';

export default function OnBoarding() {
  const { stateApp} = useAppInfo();
  const UpdateOnboarding = useUpdateOnboarding()
  const { displayName, onboarding, userId } = stateApp;
  const {objectives} = onboarding
  const [modal, setModal] = useState(true);
  const [steps, setSteps] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [username, setUsername] = useState();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  useEffect(()=>{
    if (displayName){
    setUsername(displayName)}
  },[displayName])

  // Handle change event of checkbox
  const handleCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };


  const handleNext = async (e) => {
    e.preventDefault();
    if (steps === 0) {
      await handleRegister();
    } else if (steps === 1) {
      if (objectives.length === 0) {
        setSnackbarMessage("Please select at least one objective to proceed.");
        setSnackbarOpen(true);
      }
      else{
        setSteps(steps + 1)
      }
    }
      else if (steps === 2){
        if (setIsTermsAccepted === false) {
          setSnackbarMessage("Please accept the Terms to continue.");
          setSnackbarOpen(true);
        }

        else {
          const updatedOnboarding = { ...onboarding, setDisplayName: true,terms:true};
          UpdateOnboarding(username,updatedOnboarding);
  
          const user = auth.currentUser;
          await updateProfile(user, { displayName: username });
  
          const userDocRef = doc(db, "users", userId);
          await updateDoc(userDocRef, { displayName: username });
          setModal(false)
        }
      }
  };


  const handleRegister = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/register/`, { username });
      const { access_token } = response.data;

      if (access_token === "Success") {
        setSteps((prevSteps) => prevSteps + 1);
      }
    } catch (err) {
      if (err.response?.status === 400 && err.response.data.error === 'Username already exists') {
        setError('Username already exists. Please choose a different username.');
      } else {
        setError('Registration failed');
        console.error(err);
      }
    }
  };

  return (
    <>
      {modal && (
        <Modal
          onClose={() => setModal(true)}
          backgroundVideo={true}
          sx={{ borderRadius: "4px", minHeight: "30%", 
          maxHeight: "80%",overflow:"auto", background: "var(--bgWoodSmoke)" ,maxWidth:"80%"}}
          width={"auto"}
          height={"auto"}
          closeIcon={false}
        >
          <form onSubmit={handleNext} 
          style={{ display: "flex", 
          flexDirection: "column", 
          gap: "2rem", width: "100%",boxSizing:"border-box"}}>

            {steps === 0 && (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1rem", width: "100%" }}>
     
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <Logo height="50px" width="50px" />
        <h1 style={{ color: "white" }}>Welcome to TakeStock!</h1>
      </div>
      <h2 style={{ color: "white" }}>Let's get to know you!</h2>
      <div style={{ background: "white", border: "3px solid var(--selectBlue)", width: "80%", padding: "0.5rem", boxSizing: "border-box", borderRadius: "5px", display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem" }}>
        <TextInput
          required
          label="Username"
          fontSize="1.4rem"
          sx={{ fontSize: "1.4rem", width: "100%", background: "white", margin: 0 }}
          value={username}
          onChange={(v)=>setUsername(v)}
        />
        {error && <Alert severity="warning">{error}</Alert>}
      </div>
    </div>
            )}
            {steps === 1 && (
              <>
              <OnboardObjectives username={username}/>
                 <Snackbar
                 anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                 open={snackbarOpen}
                 autoHideDuration={6000}
                 onClose={() => setSnackbarOpen(false)}
               >
                 <Alert onClose={() => setSnackbarOpen(false)} severity="warning" sx={{ width: '100%', fontSize: "1rem" }}>
                   {snackbarMessage}
                 </Alert>
               </Snackbar>
               </>
            )}
        {steps === 2 && (
        <div style={{width: "500px",display: "flex",flexDirection: "column",gap:"1rem",height:"70vh"}}>
          
          <Paper style={{ flex: 1, width: "100%",display:"flex",
          flexDirection:"column",gap:"0.5rem",padding:"0.5rem 0",overflow:"auto"}}>
            <h2 style={{textAlign:"center",fontWeight:"bold"}}>Terms & Conditions</h2>
            <Terms />
          </Paper>
          
          <div 
          onClick={()=>setIsTermsAccepted(!isTermsAccepted)}
          style={{ position: "sticky", bottom: "0"
          , zIndex: "1",boxSizing:"border-box"
          ,padding:"1rem",background:"white",borderRadius:"4px",display:"flex",gap:"0.5rem",alignItems:"center"}}>
              <input
                style={{height:"20px",width:"20px"}}
                type="checkbox"
                checked={isTermsAccepted}
                onChange={handleCheckboxChange}
                required
              />
             
            <label style={{fontSize:"1.3rem"}}>
            I accept the terms and conditions
            </label>
            
          </div>

        </div>
      )}

            <div style={{ display: "flex", justifyContent: "center", gap: "2rem", alignItems: "center" }}>
              <Button endIcon={<ArrowForwardIosIcon />} type='submit' variant="contained">
                Next
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}


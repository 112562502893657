import React, { useEffect, useRef } from 'react'
import DefaultMessageBox from './DefaultMessageBox';
import { Button, CircularProgress } from '@mui/material';
import { useAppInfo } from 'AppState';
import Logo from 'logo/Logo';
import ProfileLogo from 'components/Logo/ProfileLogo';
import useHandleUserEvents from './useHandleUserEvents';
import ContentRender from './ContentRender';


export default function ChatContent() {
  const { stateApp,stateAssistant} = useAppInfo();
  const { username } = stateApp;
  const { chats, loading,loadingMessage,questionChat} = stateAssistant;
  const chatRefx = useRef();

  useEffect(() => {
    chatRefx.current?.scrollIntoView({ behavior: 'smooth',block: 'start'});
  }, [questionChat]);  


  return (
    <>
      {chats.length === 0 && (
        <div  style={{
          display: 'flex',
          flexDirection:"column",
          padding: '2rem 4rem',
          gap: '2rem',
          flexWrap: 'wrap',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          boxSizing: 'border-box',
        }}>

        <Logo height='50px' width='50px'/>

        <div
          style={{
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent:"center",
            boxSizing: 'border-box',
          }}
        >
          <DefaultMessageBox/>
        </div>
        
        </div>
      )}

      {chats.length > 0 &&
        chats.map((obj, index) => (
          <React.Fragment key={index}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: obj.role === 'user' ? 'row-reverse' : 'row',
              }}
 
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%' }}>
                {obj.role === 'user' && (
                  <div 
                  ref={chatRefx}
                  style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row-reverse' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                      <ProfileLogo />
                      <strong>{username}</strong>
                    </div>
                  </div>
                )}
                {obj.role !== 'user' && (
                  <h4 style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <Logo height='20px' width='20px' /> <strong>Assistant</strong>
                  </h4>
                )}
                {obj.role !== 'user' && index === chats.length - 1 && (
                  <>
                    {(loading && loadingMessage) && <h4 className="fade-in-simple" style={{fontWeight:"bold"}}>{loadingMessage}</h4>}
                    {/* {loading && <CircularProgress/>} */}
                  </>
                )}
                {obj.content !== '#?$?#' && (
                  <div
                    style={{
                      background: '#fafafa',
                      borderRadius: '5px',
                      padding: '1rem',
                      width: 'auto',
                      maxWidth: obj.role === 'user'?"400px":'80%',
                      marginLeft: obj.role === 'user' && 'auto',
                    }}
                  >
                    {/* {obj.content} */}
                    {obj.role === 'user' && obj.content}
                    {obj.role !== 'user' && <ContentRender content={obj.content}/>}
                  </div>
                )}

               
               
              </div>
            </div>
            <br />
          </React.Fragment>
        ))}
    </>
  );
}

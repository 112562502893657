import { useAppInfo } from 'AppState'
import SelectBox from 'components/Inputs/SelectBox'
import React, { useEffect } from 'react'

export default function PortfolioAccountSelection({sx}) {
    const {dispatchPortfolio,statePortfolio} = useAppInfo() 
    const {accountNameSel,accountNames,accountSettings} = statePortfolio

    function handleAccountName(e,v){
        dispatchPortfolio({"accountNameSel":v})
      }

     
    
  return (
    <>    {(accountNames.length>0 && accountNameSel) && 
        <div style={{background:"white",zIndex:"100",boxSizing:"border-box",minWidth:"250px",...sx}}>
        <SelectBox fontSize='1.2rem' width="100%" variant='outlined' options={accountNames} 
        label={"Account"} value={accountNameSel} onChange={handleAccountName}/>
        </div>
        }
        </>

  )
}

import React, { useEffect, useRef } from 'react';

const FadeBackground = ({ children, style = {}, className = "" }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in-animation");
          } 
          else {
            entry.target.classList.remove("fade-in-animation"); // Reset animation
          }
        });
      },
      { threshold: 0}
    );

    const element = elementRef.current;
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={`${className} fade-background`}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // padding: "1rem",
        boxSizing: "border-box",
        overflow: "hidden",
        ...style,
      }}
    >
      {children}

    </div>
  );
};

export default FadeBackground;

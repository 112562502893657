export const financeProfessionalFormInfo = [
    {
        id: "1",
        loginDate: "2024-11-08",
        Name: "John Doe",
        Role: "Senior Financial Advisor",
        ProfessionalCategory: [],
        Description:"",
        CoreExpertise:[],
        Services: [{title:"Family Financial Planning",details:""}, {title:"Retirement Planning",details:""}],
        Organization: "Financial Insights Inc.",
        OrganizationAcheivements: "Financial Insights Inc.",
        ProfileImage: "https://example.com/images/johndoe.jpg",
        BannerImage: "https://example.com/images/banner.jpg",
        MarketingImages: ["https://example.com/images/marketing1.jpg","https://example.com/images/marketing2.jpg"], 
        Website:"",
        SocialMedia: [
          {
            instagram: { url: "https://instagram.com/johndoe_advisor", showUsers: false },
            linkedin: "https://linkedin.com/in/johndoe",
            facebook: "https://facebook.com/johndoeadvisor",
            whatsapp: "https://wa.me/1234567890"
          }
        ],
        Address: "123 Finance St, Suite 101, New York, NY, 10001",
        Location:{city:"",state:"",country:""},
        LocationServed: ["New York", "New Jersey", "Connecticut"],
        Email: "johndoe@example.com",
        PhoneNumber: "+1-123-456-7890",
        Availability:[{date:"",time:""}],
        ResponseTime:"",
        Languages: [{language:"",proficiency:""}],
        Fees: [
          { basic: "$100/hr" , details:[]},
          { standard: "$150/hr" , details:[]},
          { premium: "$200/hr" , details:[]},
          { ultra: "$300/hr", details:[] }
        ],
        Certifications: [
          {
            issuingAuthority: "Certified Financial Planner Board",
            issuedDate: "2020-01-15",
            expiryDate: "2025-01-15"
          },
          {
            issuingAuthority: "Chartered Financial Analyst Institute",
            issuedDate: "2018-08-20",
            expiryDate: "2023-08-20"
          }
        ],
        Qualifications: [
          {
            school: "Harvard University",
            degree: "Master of Business Administration",
            degreeDate: "2015-05-10"
          },
          {
            school: "University of California, Berkeley",
            degree: "Bachelor of Economics",
            degreeDate: "2012-05-15"
          }
        ],
        ShowDetails:[{email:true,phone:false,website:true,location:true,socialMedia:true}],
        Reviews: [
          {
            star: 5,
            comment: "John provided exceptional advice for my retirement planning.",
            location: "New York, NY",
            date: "2024-10-22"
          },
          {
            star: 4,
            comment: "Very knowledgeable and helpful.",
            location: "Jersey City, NJ",
            date: "2024-09-15"
          }
        ],
        ReviewsAggregate:"",
      }]

export const languages = []
export const languageProficiency = ["Basic", "Conversational", "Fluent", "Native"]

export const professionalCategory = ["Financial Advisor",
    "Financial Planner",
    "Retitrement Planner",
    "Wealth Manager",
    "Portfolio Manger",
    "Investment Advisor",
    "Mortgage Advisor",
    "Insurance Advisor"
    ,"Loan Advisor","Investment Analyst","Trade Analyst","Financial Teacher"]

    
export const professionalType = [
        {
            name: "Financial Advisor",
            url: "FinancialAdvisor",
            coreExpertise: [
                "Holistic Financial Advisory",
                "Goal-Based Financial Planning",
                "Risk Management and Insurance Planning",
                "Tax Efficiency Strategies",
                "Portfolio Diversification"
            ],
            description: "Provides comprehensive financial guidance to help you manage assets, plan for the future, and achieve their financial goals."
        },
        {
            name: "Financial Planner",
            url: "FinancialPlanner",
            coreExpertise: [
                "Family Financial Planning",
                "Cash Flow Management",
                "Retirement Planning",
                "Goal-Based Financial Planning",
                "Budget Management"
            ],
            description: "Helps you create long-term plans to meet life goals through effective budgeting, savings, and retirement strategies."
        },
        {
            name: "Retirement Planner",
            url: "RetirementPlanner",
            coreExpertise: [
                "Retirement Planning",
                "Pension Planning",
                "Social Security Optimization",
                "Retirement Income Management",
                "401(k) and IRA Advisory"
            ],
            description: "Specializes in planning for retirement by managing income streams, optimizing benefits, and preparing for post-retirement needs."
        },
        {
            name: "Wealth Manager",
            url: "WealthManager",
            coreExpertise: [
                "High-Net-Worth Portfolio Management",
                "Legacy and Estate Planning",
                "Alternative Investments Advisory",
                "Risk and Performance Analysis",
                "Tax Efficiency Strategies"
            ],
            description: "Focuses on managing and growing the wealth of high-net-worth you through tailored investment and estate planning strategies."
        },
        {
            name: "Portfolio Manager",
            url: "PortfolioManager",
            coreExpertise: [
                "Equity Portfolio Management",
                "Fixed Income Portfolio Management",
                "Investment Strategy Development",
                "Asset Allocation",
                "Risk and Performance Analysis"
            ],
            description: "Oversees investment portfolios to maximize returns while managing risk, using tailored asset allocation and investment strategies."
        },
        {
            name: "Investment Advisor",
            url: "InvestmentAdvisor",
            coreExpertise: [
                "Investment Strategy Development",
                "Portfolio Diversification",
                "Market Trend Analysis",
                "Equity Portfolio Management",
                "Alternative Asset Management"
            ],
            description: "Provides guidance on investment choices and strategies, with a focus on portfolio diversification and market trends."
        },
        {
            name: "Mortgage Advisor",
            url: "MortgageAdvisor",
            coreExpertise: [
                "Home Purchase Financing",
                "Refinancing Options",
                "Loan Pre-Approval",
                "Credit Assessment for Mortgage",
                "Debt Consolidation"
            ],
            description: "Assists you in navigating the mortgage process, offering expertise in financing options, refinancing, and loan approvals."
        },
        {
            name: "Insurance Advisor",
            url: "InsuranceAdvisor",
            coreExpertise: [
                "Risk Management and Insurance Planning",
                "Life Insurance Planning",
                "Health Insurance Advisory",
                "Property and Casualty Insurance",
                "Long-Term Care Insurance"
            ],
            description: "Helps you understand and select appropriate insurance options to protect assets and manage personal risks."
        },
        {
            name: "Loan Advisor",
            url: "LoanAdvisor",
            coreExpertise: [
                "Personal Loan Consulting",
                "Business Loan Advisory",
                "Student Loan Refinancing",
                "Debt Consolidation",
                "Credit Assessment for Mortgage"
            ],
            description: "Provides consulting on various loan options, focusing on debt management, refinancing, and credit assessments."
        },
        {
            name: "Investment Analyst",
            url: "InvestmentAnalyst",
            coreExpertise: [
                "Equity Analysis",
                "Bond Market Analysis",
                "Market Trend Analysis",
                "Economic Forecasting",
                "Industry Sector Research"
            ],
            description: "Conducts in-depth research and analysis of markets and investments to guide informed investment decisions."
        },
        {
            name: "Trade Analyst",
            url: "TradeAnalyst",
            coreExpertise: [
                "Stock Trading Strategies",
                "Commodity Market Analysis",
                "Foreign Exchange Trading",
                "Risk Management in Trading",
                "Economic Forecasting"
            ],
            description: "Focuses on analyzing and developing strategies for trading in various financial markets, including stocks and commodities."
        },
        {
            name: "Financial Teacher",
            url: "FinancialTeacher",
            coreExpertise: [
                "Budget Management",
                "Cash Flow Management",
                "Goal-Based Financial Planning",
                "Family Financial Planning",
                "Personal Loan Consulting"
            ],
            description: "Educates individuals or groups on personal finance topics, aiming to improve financial literacy and budgeting skills."
        }
    ];
    
    export const coreExpertise = [
        "Holistic Financial Advisory",
        "Retirement Planning",
        "Tax Efficiency Strategies",
        "Family Financial Planning",
        "Budget Management",
        "Goal-Based Financial Planning",
        "Cash Flow Management",
        "Risk Management and Insurance Planning",
        "Pension Planning",
        "401(k) and IRA Advisory",
        "Social Security Optimization",
        "Retirement Income Management",
        "High-Net-Worth Portfolio Management",
        "Legacy and Estate Planning",
        "Philanthropy and Charitable Giving",
        "Alternative Investments Advisory",
        "Equity Portfolio Management",
        "Fixed Income Portfolio Management",
        "Alternative Asset Management",
        "Risk and Performance Analysis",
        "Investment Strategy Development",
        "Asset Allocation",
        "Portfolio Diversification",
        "Market Trend Analysis",
        "Home Purchase Financing",
        "Refinancing Options",
        "Loan Pre-Approval",
        "Credit Assessment for Mortgage",
        "Life Insurance Planning",
        "Health Insurance Advisory",
        "Property and Casualty Insurance",
        "Long-Term Care Insurance",
        "Personal Loan Consulting",
        "Business Loan Advisory",
        "Student Loan Refinancing",
        "Debt Consolidation",
        "Equity Analysis",
        "Bond Market Analysis",
        "Industry Sector Research",
        "Economic Forecasting",
        "Stock Trading Strategies",
        "Commodity Market Analysis",
        "Foreign Exchange Trading",
        "Risk Management in Trading",
        "Alternative Asset Analysis",
        "Philanthropic Advisory"
    ];
    
import React, { useEffect, useState } from "react";
import { useAppInfo } from "AppState";
import IndSecConstituentsScatter from "./Constituents/IndSecConstituentsScatter";
import IndSecConstituentsHeatmap from "./Constituents/IndSecConstituentsHeatmap";
import IndSecConstituentsTechnicals from "./Constituents/IndSecConstituentsTechnicals";
import IndSecConstituentsVisuals from "./Constituents/IndSecConstituentsVisuals";
import IndSecContent from "./IndSecContent";
import SelectMarket from "components/Inputs/SelectMarket";
import IndSecOverview from "./IndSecOverview";
import IndSecLayout from "./IndSecLayout";
import PageHeaderIcons from "nav/PageHeaderIcons";
import SelectBox from "components/Inputs/SelectBox";
import { assetCode, dfin } from "content/constants";

function IndSecInfoList() {
  const { dispatchIndustry, stateIndustry, stateTicker } = useAppInfo();
  const { tickerSelInfo, tickerType } = stateTicker;
  const { menuSel, analysisOptionSel } = stateIndustry;
  const maxItemsPerPage = 50
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, settotalItems] = useState(1);

  useEffect(() => {
    const indexOfLastItem = currentPage * maxItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - maxItemsPerPage;
    const infoX = stateIndustry.constiInfoAll;
    const infoDisplay = infoX.slice(indexOfFirstItem, indexOfLastItem)
    setItemsPerPage(infoDisplay.length)
    settotalItems(infoX.length);
    dispatchIndustry({constiInfo: infoDisplay,});
  }, [currentPage, stateIndustry.constiInfoAll]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    stateIndustry.indSecMode,
    stateIndustry.countrySel,
    stateIndustry.industrySel,
    stateIndustry.sectorSel,
  ]);

  function handlePage(page) {
    setCurrentPage(page);
  }

  function handleCountry(v) {
    dispatchIndustry({ countrySel: [v] });
  }

  useEffect(() => {
    if (
      analysisOptionSel === "Active Ticker" &&
      tickerType === assetCode?.Stocks
    ) {
      handleActiveTicker();
    }
  }, [analysisOptionSel]);

  function handleActiveTicker() {
    if (tickerType === assetCode?.Stocks) {
      dispatchIndustry({
        analysisOptionSel: "Active Ticker",
        sectorSel: [tickerSelInfo?.[dfin.gicSector]],
        industrySel: [tickerSelInfo?.[dfin.gicIndustry]],
        countrySel: [tickerSelInfo?.[dfin.country]],
        searchIndSec: false,
      });
    }
    // handleModal()
  }
  return (
    <div className="containerColumnFlex">
      <div className="page_header_box">
        <div
          className="page_header_title_box"
        >
          <SelectMarket
            handleMarket={handleCountry}
            defaultMarketOptions={["USA", "India", "Canada", "UK"]}
            defaultMarket={stateIndustry.countrySel}
          />
          <h2 style={{ fontWeight: "bold" }}>Sector/Industry</h2>
        </div>

        <div className="page_header_content_box">
        <SelectBox
              variant="outlined"
              label={"Content"}
              width="250px"
              options={[
                "Constituents",
                "Summary",
                "Active Ticker",
                "Etf Overview",
              ]}
              value={analysisOptionSel}
              onChange={(e, v) => dispatchIndustry({ analysisOptionSel: v })}
            />
        </div>

        <div className="page_header_toolkit">
          {tickerType === assetCode?.Stocks && (
            <PageHeaderIcons
              onClick={handleActiveTicker}
              name="Active Ticker"
              title={"See Sector/Industry of Active Ticker"}
            />
          )}
          <PageHeaderIcons name="Explore" />
        </div>
      </div>

      {analysisOptionSel !== "Etf Overview" && (
        <div
          className="containerColumnFlex1"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IndSecLayout
            tabContent={infoList[menuSel]}
            handlePage={handlePage}
            pageDetails={{ itemsPerPage, totalItems, currentPage }}
          />
        </div>
      )}

      {analysisOptionSel === "Etf Overview" && (
        <div className="containerColumnFlex1">{infoList["Overview"]}</div>
      )}
    </div>
  );
}

const infoList = {
  Overview: <IndSecOverview />,
  Performance: <IndSecContent info={"Performance"} />,
  Financials: <IndSecContent info={"Financials"} />,
  Technicals: <IndSecContent info={"Technicals"} />,
  Value: <IndSecContent info="Value" />,
  "Market Stats": <IndSecContent info="Market Stats" />,
  Growth: <IndSecContent info="Growth" />,
  "Size & Share": <IndSecContent info="Size & Share" />,
  Heatmap: <IndSecConstituentsHeatmap />,
  Scatter: <IndSecConstituentsScatter />,
  "Brand Visuals": <IndSecConstituentsVisuals />,
};

export { IndSecInfoList };

import { Button } from '@mui/material'
import React, { useState } from 'react'
import ThreePIcon from '@mui/icons-material/ThreeP';
import Modal from 'components/Modal/Modal';
import ComingSoon from 'components/ComingSoon';
import { FinanceProfessionalExploreModal } from 'finanaceProfessional/FinanceProfessionalExplore';

export default function FinancialAdvisorConnect({variant="highlight",sx={}}) {
  const [modal,setModal] = useState(false)
  return (
    <>
    <FinanceProfessionalExploreModal label="Connect with Financial Advisors" professional="Financial Advisor" />
    </>
   
  )
}
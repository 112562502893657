import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState';
import { Button } from '@mui/material';
import { calculateAmortizationSchedule, extrapolateCashflow } from '../Utils/utils';
import CashflowChart from '../Cashflow/CashflowChart';
import TableGen from 'components/Table/TableGen';
import { InputAmount, InputCompounding, InputDate, InputForecastPeriod, InputGrowthRate, InputInterestRate, InputLoanTerm, InputName, InputPaybackPeriod, InputPeriod, InputReturnExpected, InputType, InputVolatility } from './FieldFormCells';
import useFieldForm from './useFieldForm';


export default function FieldFormOnboarding({data=[],
  defaultField,addField,showType=true,typeOptions,storeName,name="Assets",returnExpected=false,
  compounding=false,interestRate=false,paybackPeriod=false,volatility=false,period=false,
  loanTerm=false,projectionType="growth",forecastPeriod=false,growthRate=false,table=false}) {
  
    const {dispatchMyFinancials,stateMyFinancials} = useAppInfo()
   
    const {onBoardingStep} = stateMyFinancials
    const dataDefault = data
    console.log(data,storeName)
    const {
      fields,
      savedId,
      error,
      handleChange,
      handleSubmit,
    } = useFieldForm({storeName,data,defaultField,onboarding:true})


    const [chartData,setChartData] = useState()
    const [tableData,setTableData] = useState([])

    useEffect(()=>{
      if (fields.length > 0 ){
      let projection = []
      let chartType = "column"
      let table = []
      if (projectionType === "liabilities"){
        const liabilityDist = fields.flatMap(obj => {
          const {amortizationSchedule}= calculateAmortizationSchedule(obj);
          return amortizationSchedule || [];
        });
        
        const chartData = liabilityDist.flatMap(obj => [
           { name: "Total", amount: obj.amount, date: obj.date},
           { name: "Interest", amount: obj.interest, date: obj.date},
           { name: "Principal", amount: obj.principal, date: obj.date},
           
       ]);
        projection = chartData
        chartType = "area"
        table = liabilityDist
      }
      else if (projectionType === "assets"){
        projection = extrapolateCashflow({fields:fields,growthKey:"return",showVolatility:false})
        
      }
      else {
        projection = extrapolateCashflow({fields:fields,showVolatility:false})
      }

        setChartData({data:projection,chartType:chartType})
        setTableData(table)
      }

       
    },[fields])

   
  return (
    <div style={{height:"100%",width:"100%"
    ,padding:"1rem",display:"flex",gap:"2rem",borderRadius:"4px",boxSizing:"border-box"}}>
    
    <div style={{display:"flex",flexDirection:"column"
    ,gap:"1rem",minWidth:"400px",width:"30%",background:"white",background:"white",padding:"1rem",borderRadius:"1rem"}}>
    <h1>{name}</h1>
    
    <form onSubmit={handleSubmit} style={{display:"flex",flexDirection:"column",gap:"1rem"
    ,width:"100%",boxSizing:"border-box",height:"100%",flex:1}}>

    {fields.map((fieldObj, index) => (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",flex:1}} key={index}>
    
    {showType && <InputType label fieldObj={fieldObj} handleChange={handleChange} typeOptions={typeOptions}/>}
    <InputName label fieldObj={fieldObj} handleChange={handleChange} typeOptions={typeOptions}/>
    <InputAmount label fieldObj={fieldObj} handleChange={handleChange}/>
    <InputDate label fieldObj={fieldObj} handleChange={handleChange}/>
    
    {period && <InputPeriod label fieldObj={fieldObj} handleChange={handleChange}/>}
    <div style={{display:"flex",gap:"1rem"}}>
    {interestRate &&
      <InputInterestRate label fieldObj={fieldObj} handleChange={handleChange}/>}
    {compounding && 
       <InputCompounding label fieldObj={fieldObj} handleChange={handleChange}/>}
    </div>
      {paybackPeriod && 
      <InputPaybackPeriod label fieldObj={fieldObj} handleChange={handleChange}/>}
    
    {loanTerm && 
<>
<div style={{display:"flex",width:"100%",gap:"0.2rem",boxSizing:"border-box"}}>
  <h4>Loan Term:</h4>
  <InputLoanTerm label fieldObj={fieldObj} handleChange={handleChange}/>
  </div>
</>
}
    </div>
    ))}

{error && <h5 style={{color:"red"}}>{error}</h5>}
<div style={{display:"flex",gap:"1rem"}}>
  <h5>*You can add and update more details later.</h5>
  </div>

    <div style={{display:"flex",gap:"1rem",width:"100%",boxSizing:"border-box"}}>
    {onBoardingStep>0 && <Button onClick={()=>{dispatchMyFinancials({"onBoardingStep":onBoardingStep-1})}}>Back</Button>}
      <Button sx={{marginLeft:"auto"}} variant='contained' type='submit'>Next</Button>
     
      </div>    
    </form>
    </div>
    <div style={{flex:1,background:"white",padding:"1rem"
    ,borderRadius:"1rem",display:"flex",flexDirection:"column",minWidth:0,overflow:"auto"}}>

      <h2 style={{fontWeight:"bold"}}>Projections</h2>
      <div style={{width:"100%",height:"400px",minHeight:"300px",flex:1}}>
      {chartData && <CashflowChart data={chartData?.data} title='' chartType={chartData?.chartType}/>}
     
      </div>
      
      {(table && tableData.length>0) && <TableGen data={tableData}/>}

      {fields.map((fieldObj, index) => (
      <div style={{display:"flex",flexWrap:"wrap",width:"100%",gap:"1rem",boxSizing:"border-box"}} key={index}>
       {forecastPeriod && <InputForecastPeriod sx={{width:'40%'}} variant={"outlined"} label fieldObj={fieldObj} handleChange={handleChange}/>}
      {growthRate && <InputGrowthRate sx={{width:'40%'}} variant={"outlined"} label fieldObj={fieldObj} handleChange={handleChange}/>}
      
      {returnExpected && <InputReturnExpected  sx={{width:'40%'}} variant={"outlined"} label fieldObj={fieldObj} handleChange={handleChange}/>
       }
      
      {volatility && <InputVolatility sx={{width:'40%'}} variant={"outlined"} label fieldObj={fieldObj} handleChange={handleChange}/>}
      
      </div>))}
     
    </div>
    </div>
  )
}

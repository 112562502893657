import React, { useState } from 'react';
import AddTransaction from './AddTransaction';
import Modal from 'components/Modal/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import { useAppInfo } from 'AppState';
import useAddPortfolio from 'data/user/portfolio/useAddPortfolio';
import { Alert, Button } from '@mui/material';
import UploadPortfolio from './UploadPortfolio';
import { generateUniqueId } from 'utils/utilGeneral';
import { assetCode, dfin } from 'content/constants';


export default function PortfolioAddTransactionModal() {
  const {dispatchPortfolio,statePortfolio,stateApp,stateTicker} = useAppInfo()
  const tickerInfo = stateTicker.tickerInfo;
  const [transactionDetails,setTransactionDetails] = useState([{ id: Date.now() }])

  function handleAddMoreTransaction() {   
    setTransactionDetails((prevTransactions) => [...prevTransactions, { id: Date.now() }]);
  }

  function handleDeleteTransaction(id) {
    setTransactionDetails((prevTransactions) => prevTransactions.filter((transaction) => transaction.id !== id));
  }

  function handleAddTransactionModal() {
    dispatchPortfolio({"addTransaction":false})
  }
  function handleTransactionDetails(transaction) {
    setTransactionDetails((prevDetails) => {
      const existingIndex = prevDetails.findIndex((detail) => detail.id === transaction.id);
  
      if (existingIndex !== -1) {
        // Return a new array with the updated transaction without mutating prevDetails
        return [
          ...prevDetails.slice(0, existingIndex),
          transaction,
          ...prevDetails.slice(existingIndex + 1),
        ];
      } else {
        // Append new transaction by returning a new array
        return [...prevDetails, transaction];
      }
    });
  }
  
  const {addPortfolio} = useAddPortfolio({dispatchPortfolio,stateApp})
  function handleAddPortfolio(e) {
    e.preventDefault();
    const { accountNameSel, portfolio } = statePortfolio;
    const accountDetails = transactionDetails;
    
    let updatedTransactions = []
    // Check if the account already exists in the portfolio
    const existingAccount = portfolio?portfolio[accountNameSel]:undefined;
    if(existingAccount){
      updatedTransactions = [
        ...existingAccount,
        ...accountDetails,
      ];
    }
    else{
      updatedTransactions = [...accountDetails]
    }
    
    const updatedPortfolio = {
      ...portfolio,
      [accountNameSel]: updatedTransactions,
    };
    
    if (transactionDetails.map((transaction) => transaction.tickerError).includes(true)) {
      return alert('Please enter a valid ticker for all transactions.');
    }

    addPortfolio(accountNameSel,accountDetails)
    dispatchPortfolio({'portfolio':updatedPortfolio,'addTransaction':false});
  }

  function handleUpload(data) {
    if (data.length > 0) {
      const transactions = data.map((transaction) => {
        const tickerInfoItem = tickerInfo.find(obj => obj[dfin.ticker] === transaction.ticker);
        return {
          id: generateUniqueId(),
          TICKER: transaction?.ticker,
          transactionType: transaction?.transactionType || "Buy",
          investmentType: transaction?.ticker_type || assetCode?.Stocks,
          date: transaction?.buy_date || new Date(),
          quantity: transaction?.quantity,
          price: transaction?.price,
          ...tickerInfoItem,
          tickerError: tickerInfoItem ? false : true,
        };
      });
  
      setTransactionDetails((prevTransactions) => [...prevTransactions, ...transactions]);
    }
  }

  return (
    <Modal onClose={handleAddTransactionModal} width="80%">
      <form
      onSubmit={handleAddPortfolio} 
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          gap: '1rem',
          boxSizing: 'border-box',
        }}
      > 
      <div style={{display:"flex",alignItems:"center"}}><h2>Add Transactions</h2>
      
      </div>
        <div style={{ flex: 1, overflow: 'auto', paddingBottom: '4rem' }}>
          {transactionDetails.map((transaction) => (
            <div style={{ width: '100%', display: 'flex', 
            alignItems: 'center',position:"relative",padding:transaction.tickerError&&"3rem 0"}} key={transaction.id}>
              {transaction.tickerError && <div style={{position:"absolute",top:"0",padding:"0",width:"100%",height:"100%",border:"2px solid red"}}> 
                <Alert severity="error">Ticker {transaction.TICKER} not found! Select a valid ticker!</Alert>
                </div>}
              <AddTransaction transactionDetails={transaction} handleTransactionDetails={handleTransactionDetails} />
              <Button onClick={() => handleDeleteTransaction(transaction.id)} >
                <CloseIcon sx={{ color: 'gray' }}/>
              </Button>
            </div>
          ))}
        </div>
        <div style={{ alignSelf: 'center',display:"flex",gap:"1rem"}}>
          <Button
            onClick={handleAddMoreTransaction}
          >
            <Add /> Add More Transactions
          </Button>
          <UploadPortfolio handleTableData={handleUpload}/>
        </div>
        <div style={{ justifySelf: 'flex-end' }}>
          <Button type='submit' variant='contained'>Add to Portfolio</Button>
        </div>
      </form>
    </Modal>
  );
}

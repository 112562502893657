import React, { useState } from 'react';
import { Button, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { financeProfessionalFormDetails } from 'finanaceProfessional/FinanceProfessionalFormFields';
import SendIcon from '@mui/icons-material/Send';

const info = financeProfessionalFormDetails
export default function FinanceProfessionalChat() {
  const { name:professionalName, onlineStatus, averageResponseTime,profileImage} = info;
  const [isOpen, setIsOpen] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const togglePopup = () => setIsOpen(!isOpen);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setChatData([...chatData, { message: newMessage, isUser: true }]);
      setNewMessage('');
    }
  };

  return (
    <div style={{ position: "relative", width: "100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
      {/* Chat Button */}
      <div onClick={togglePopup} style={{ display: "flex", 
          width: "35vw",
          minWidth:"300px", 
          gap: "0.5rem",
          boxSizing: "border-box", 
          background: "#f7f7f7",
          borderRadius: "25px", 
          padding: "0.5rem 0.5rem",
          position: "relative",
          background: "white",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)"}}>
        <Avatar alt={professionalName} src={profileImage} style={{ marginRight: '8px','& img': {objectFit: 'contain'}}} />
        <div style={{flex:1}}>
          <div style={{ fontSize: '1rem', fontWeight: '600' }}>Message {professionalName}</div>
          <div style={{ fontSize: '0.875rem', color: '#6b6b6b' }}>{onlineStatus} • Avg. response time: {averageResponseTime}</div>
        </div>
        <Button 
            onClick={togglePopup}
            sx={{ minWidth: 0, margin: 0 }}
          >
            <SendIcon />
          </Button>
      </div>

      {/* Chat Popup */}
      {isOpen && (
        <div style={{
          position: 'absolute',
          bottom: '100%',
          left: '0',
          width: '100%',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
          backgroundColor: 'white',
          overflow: 'hidden',
          zIndex: 1000,
          marginBottom: '10px' // Space between button and popup
        }}>
          {/* Header */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid #ddd',
            backgroundColor: '#f5f5f5',
          }}>
            <Avatar alt={professionalName} src="https://via.placeholder.com/150" style={{ marginRight: '8px' }} />
            <div style={{ flexGrow: 1 }}>
              <div style={{ fontSize: '1rem', fontWeight: '600' }}>{professionalName}</div>
              <div style={{ fontSize: '0.875rem', color: '#6b6b6b' }}>{onlineStatus} • Avg. response time: {averageResponseTime}</div>
            </div>
            <IconButton onClick={togglePopup} size="small">
              <CloseIcon />
            </IconButton>
          </div>

          {/* Chat Content */}
          <div style={{ padding: '16px', maxHeight: '500px', minHeight: "300px", overflowY: 'auto' }}>
            {chatData.length > 0 ? chatData.map((chat, index) => (
              <div key={index} style={{ marginBottom: '8px', display: 'flex', flexDirection: chat.isUser ? 'row-reverse' : 'row' }}>
                <div style={{
                  backgroundColor: chat.isUser ? '#007bff' : '#f1f1f1',
                  color: chat.isUser ? 'white' : 'black',
                  padding: '8px 12px',
                  borderRadius: '16px',
                  maxWidth: '75%',
                  textAlign: 'left',
                }}>
                  {chat.message}
                </div>
              </div>
            )) : (
              <div style={{ fontSize: '0.875rem', color: '#888' }}>Ask {professionalName} a question or share your project details...</div>
            )}
          </div>

          {/* Chat Input */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px',
            borderTop: '1px solid #ddd',
            backgroundColor: '#fafafa'
          }}>
            <IconButton size="small">
              <SentimentSatisfiedAltIcon />
            </IconButton>
            <IconButton size="small">
              <AttachFileIcon />
            </IconButton>
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={`Ask ${professionalName} a question or share your project details...`}
              style={{
                flexGrow: 1,
                padding: '8px',
                fontSize: '0.875rem',
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ borderRadius: '20px', padding: '6px 12px', fontSize: '0.875rem', textTransform: 'capitalize' }}
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

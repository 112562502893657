import React, { useState } from "react";
import TabGeneral from "components/Tabs/TabGeneral";
import HomePageTemplate from "../../home/HomePageTemplate";
import TextWithBorders from "components/Text/TextWithBorders";

export default function About() {
  const [tabIndex, setTabIndex] = useState(0);

  function handleTabChange(index) {
    setTabIndex(index);
  }
  return (
    <HomePageTemplate pageName="About Us">
      
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding:"1rem",paddingBottom:"3rem",display:"flex",flexDirection:"column",gap:"4rem"
        }}
      >
             
          <div>
          <TextWithBorders text={"Who are we?"} sx={{fontSize:"1.3rem"}}/>
          <h1 style={{padding:"1rem",width:"70%"}}>TakeStock is a personal finance and financial market analytics company. We leverage AI to assess the impact of every market and economic development on personal finances, providing timely alerts, expert guidance, and identifying new opportunities.</h1>
          
          </div>
          <div>
          <TextWithBorders text={"Our Mission"} sx={{fontSize:"1.3rem"}}/>
          <h1 style={{padding:"1rem",width:"70%"}}>Empower individuals to take stock of their financial future.</h1>
          </div>
          
          <div>
          <TextWithBorders text={"Our Team"} sx={{fontSize:"1.3rem"}}/>
          <div style={{ width: "60%",padding:"2rem 1rem"}}>
            {/* <h1  style={{fontSize:"3rem"}}>Team</h1> */}
            <div style={{ display: "flex", gap: "3rem"}}>
              <div style={{display:"flex",flexDirection:"column",gap:"1rem",alignItems:"center"}}>
                <img
                  src="/p1.jpg"
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
                <h3>Founder</h3>
              </div>
              <div>
                <h2 style={{ fontWeight: "bold"}}>Hi, I am Yash.</h2>
                <h3 >
                  I am the developer and founder of TakeStock, based on the
                  stunning Vancouver Island in Canada. My background in
                  accounting and equity markets has given me valuable
                  experience, which now fuels my passion for using mathematics
                  and technology to address complex financial and human
                  challenges. However, my interests extend far beyond numbers
                  and spreadsheets. As an avid nature enthusiast, I hold a firm
                  belief in prioritizing environmental impact, both in my
                  personal and professional life. Whether I'm exploring the
                  great outdoors or working on my latest project, I strive to
                  make a positive impact on the world around me.
                </h3>
              </div>
            </div>
          </div>
          </div>
 
          </div>
    </HomePageTemplate>
  );
}


                {/* <h1
                  className="gradient_text"
                  style={{
                    margin: "3rem 0 0 0",
                    padding: 0,
                    fontWeight: "bold",
                    fontSize: "3rem",
                    textAlign:"left"
                  }}
                >
                  Our Mission
                </h1>
                <h1
                  style={{
                    margin: "1rem 0 0 0",
                    padding: 0,
                    fontWeight: "400",
                  }}
                >
                  Empower individuals on their financial journey, enabling them to make informed and confident decisions.
                </h1> */}
{/* <h1
style={{
  margin: "3rem 0 0 0",
  padding: 0,
  fontWeight: "bold",
  fontSize: "3rem",
}}
>
Why did we build TakeStock?
</h1>
<h2 style={{ margin: "1rem 0 0 0", textAlign: "justify" }}>
TakeStock was conceived to address the pressing need for a
comprehensive financial market analysis system that is not only
accessible but also intuitive and user-friendly.
<br />
<br />
In today's financial landscape, investors and traders invest a
considerable amount of time collecting and processing data from
various sources. Often, they resort to using tools like Excel and
Jupyter Notebook for analysis. However, we firmly believe that the
future of financial market analytics lies in a unified platform.
<br />
<br />
The current options in the market often fall short in one of two
critical aspects: either they lack depth and usability or, despite
having rich data sources and integrity, they compromise on
usability and data visuals. Additionally, these systems may demand
substantial time for data processing, hindering the ability to
perform sophisticated analysis efficiently.
<br />
<br />
Enter TakeStock – a revolutionary tool designed to be accessible,
user-friendly, and comprehensive. It provides a one-stop solution
for researching and analyzing financial markets. TakeStock aims to
empower users by providing them a robust framework, enabling them
to make informed investment decisions and potentially generate
alpha returns.
<br />
<br />
Moreover, TakeStock not only simplifies financial market analysis
but also offers a unique learning and application feature. Users
can leverage the platform to enhance their understanding of market
dynamics, learn from real-time data, and apply their knowledge
through practical analysis. Additionally, TakeStock empowers users
to effortlessly generate insightful reports, receive market calls,
and gain valuable insights, fostering a holistic approach to
financial decision-making. The platform's commitment to user
education and actionable intelligence sets it apart in the
ever-evolving landscape of financial market tools.
<br />
<br />
In essence, TakeStock envisions a future where financial market
analytics are not only powerful but also seamlessly integrated
into a single, user-friendly platform, saving valuable time and
enhancing the overall experience for investors and traders.
</h2> */}
import { useState, useEffect } from 'react';
import { generateRandomColor, generateUniqueId } from 'utils/utilGeneral';
import UpdateAllocation from '../Utils/AllocationUpdate';
import useAddFinancials from 'data/user/financials/useAddFinancials';
import { useAppInfo } from 'AppState';
import { set } from 'lodash';

function useFieldForm({storeName,data=[],defaultField,onboarding=false}) {
  const {dispatchMyFinancials, stateMyFinancials} = useAppInfo()
  const storeData = stateMyFinancials[storeName]

  let defaultFieldX = defaultField ? defaultField : {id:generateUniqueId(), amount: 0, date: new Date(), name: '',edit:true}
  const [fields, setFields] = useState([]);
  const [savedId, setSavedIds] = useState([]);
  const [error, setError] = useState();
  const { addFinancials } = useAddFinancials();

  useEffect(() => {
    if (onboarding) {
      const newField = { ...defaultFieldX, id: generateUniqueId() };
      setFields([newField]);
      setSavedIds([newField.id])
    }
  }, [onboarding]);

  useEffect(() => {
    if (data.length > 0) {
      const updateStoreData = MergeData(storeData,data)
      let updatedData = UpdateAllocation(updateStoreData);
      const fieldData = updatedData.filter(updateItem => 
        data.some(dataItem => dataItem.id === updateItem.id)
      );
      setFields(fieldData);
      setSavedIds(fieldData.map((obj) => obj.id));
    } else {
      // console.log('no data');
      const newField = { ...defaultFieldX, id: generateUniqueId() };
      setFields([newField]);
      setSavedIds([newField.id])
    }

  }, [storeData]);

  const handleChange = (name, value, id) => {
    setError(null);
    let updatedFields = fields.map((fieldObj) => {
      if (fieldObj.id === id) {
        return { 
          ...fieldObj,
          [name]: value,
        };
      }
      return fieldObj;
    });

    if (savedId.includes(id)) {
      setSavedIds((savedId) => savedId.filter((obj) => obj !== id));
    }

    if (name === 'amount') {
      const total = updatedFields.reduce((total, fieldObj) => total + Number(fieldObj.amount), 0);

      updatedFields = updatedFields.map((fieldObj) => {
        const currentAllocation = total === 0 ? 0 : ((fieldObj.amount / total) * 100).toFixed(2);
        return {
          ...fieldObj,
          currentAllocation: currentAllocation,
          differenceAllocation: currentAllocation - fieldObj.targetAllocation,
        };
      });
    }

    setFields(updatedFields);
  };

  const handleAddField = () => {
    const uniqueId = generateUniqueId()
    const newField = { ...defaultFieldX, id: uniqueId,color:generateRandomColor()};
    setFields([...fields, newField]);
  };

   const handleRemoveField = id => {
    const updatedFields = fields.filter((obj, i) => obj.id !== id)
    if (updatedFields.length>0){
    setFields(updatedFields);}
    else{
      const newField = { ...defaultFieldX, id:generateUniqueId()};
      setFields([newField])
    }
  };

  const handleDeleteField = (id) => {
    const updatedFields = storeData.filter((obj, i) => obj.id !== id)
    setFields(updatedFields);
    dispatchMyFinancials({[storeName]:updatedFields });
    addFinancials(updatedFields,storeName)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let updatedStoreData = MergeData(storeData,fields)
    if (onboarding){
      const storeSelectedData = storeData.filter(field => field.type === "Investment Portfolio");
      updatedStoreData = MergeData(storeSelectedData,fields)
    }
    const names = updatedStoreData.map(field => field.name);
    const hasDuplicate = names.some((name, idx) => names.indexOf(name) !== idx);
    
    if (hasDuplicate) {
      setError('Duplicate Names found. Please ensure all Names are unique.');
      return;
    }
    setError(null)
    addFinancials(updatedStoreData,storeName)
    dispatchMyFinancials({[storeName]:updatedStoreData});
    if (onboarding){
    dispatchMyFinancials({"onBoardingStep":stateMyFinancials.onBoardingStep+1})}

    const progressUpdate = stateMyFinancials?.profileCompletion
    if (Object.keys(progressUpdate).includes(storeName)){
    progressUpdate[storeName] = true
    dispatchMyFinancials({"profileCompletion":progressUpdate});
    setTimeout(()=>addFinancials( progressUpdate,"profileCompletion"),1000)
  }

  return true
};

  return {
    fields,
    savedId,
    error,
    handleChange,
    handleDeleteField,
    handleAddField,
    handleRemoveField,
    handleSubmit,
  };
}

export default useFieldForm;

export function useHandleDeleteField({storeName}){
  const {dispatchMyFinancials, stateMyFinancials} = useAppInfo()
  const { addFinancials } = useAddFinancials();
  const storeData = stateMyFinancials[storeName]
  function handleDeleteField(id){
    const updatedFields = storeData.filter((obj, i) => obj.id !== id)
    dispatchMyFinancials({[storeName]:updatedFields });
    addFinancials(updatedFields,storeName)
  }
 

  return {handleDeleteField}
}


function MergeData(storeData, newData) {
  const dataMap = new Map();

  // Add all items from storeData to the Map
  storeData.forEach(item => dataMap.set(item.id, item));

  // Add/replace items from newData in the Map
  newData.forEach(item => dataMap.set(item.id, item));

  // Convert the Map values back to an array
  return Array.from(dataMap.values());
}

import React from 'react'
import ProductIntro from './product/ProductIntro'
import { Typography } from '@mui/material'
import Logo from '../logo/Logo'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppInfo } from 'AppState'

export default function HomeFooterBig() {
    const {stateApp} = useAppInfo()
    const navigate = useNavigate()
    const location = useLocation()
    const page = location.pathname.split("/")[1]
    function handleNavigate(v){
        navigate(`/${v}`)
    }
    const menuOptions = stateApp.isLoggedIn ? 
    ["Home","Product","Pricing","Profile","Support","About","Contact","Disclaimer","Terms"] : 
    ["Features","Pricing","Support","About","Contact","Disclaimer","Terms"]
 
  return (
    <div style={{minHeight:"100vh",width:"100%",background:"black"
    ,display:"flex",flexDirection:"column",justifyContent:"center"
    ,alignItems:"center",gap:"1rem",padding:"4rem",boxSizing:"border-box",zIndex:100}}>
    {/* <ProductIntro/> */}
    <Logo height='25px' width="150px" type='long'/>
    <Typography style={{color:"white",width:"40%",textAlign:"center"}}>This is a Beta Version.</Typography>
    
    <div style={{display:"flex",gap:"2rem",alignItems:"center",position:"relative"}}>
            {menuOptions.map(obj=>
            <Typography 
            onClick={()=>handleNavigate(obj)}
            key={obj} 
            style={{cursor:"pointer",color:obj===page ? 
            "var(--lightBlue)":"white"}}>{obj}</Typography>)}
          
          </div>
          <Typography style={{color:"white",width:"40%",textAlign:"center",padding:"2rem"}}>Please read the Terms and Disclaimer before using the product.</Typography>
    </div>
  )
}

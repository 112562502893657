import React, { useEffect, useRef } from 'react';

const FadeInText = ({ children, style = {}, className = "" }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          } else {
            entry.target.classList.remove('fade-in'); // Remove class to re-trigger animation
          }
        });
      },
      { threshold: 0.1 }
    );

    const element = elementRef.current;
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <h1 ref={elementRef} className={className} style={style}>
      {children}
    </h1>
  );
};

export default FadeInText;


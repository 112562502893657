import { useAppInfo } from 'AppState'
import CrudModal from 'components/Modal/CrudModal'
import useAddFinancials from 'data/user/financials/useAddFinancials'
import useDeletePortfolio from 'data/user/portfolio/useDeletePortfolio'
import React from 'react'

export default function PortfolioCrud() {
    const {statePortfolio,dispatchPortfolio,stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {addFinancials} = useAddFinancials()
    const {deletePortfolio} = useDeletePortfolio()

    function handleCreateAccount(accountNameSelected){
        dispatchPortfolio({
          accountNames: [accountNameSelected, ...statePortfolio.accountNames],
          accountNameSel: accountNameSelected,
          addTransaction: true,
          portfolio: {...statePortfolio.portfolio,[accountNameSelected]:[]},
          portfolioSel: [],
          // portfolioOverview:undefined,
          // portfolioTable:[],
          // portfolioTransactionTable:[],
          // tickerSelected:[],
          // tickerSelectedCode:[],
          // tickerCodeMap:{},
          // portfolioValue:[],
          // close:[],
          // trendTable:[],
        });
        const progressUpdate = stateMyFinancials?.profileCompletion
        progressUpdate["portfolio"] = true
        dispatchMyFinancials({"profileCompletion":progressUpdate});
        addFinancials( progressUpdate,"profileCompletion")
      }
    
       
  function handleDeleteAccount() {
    const accountToDelete = statePortfolio.accountNameSel;
    deletePortfolio(accountToDelete);
    dispatchPortfolio({ deleteAccount: false });
  
    let updatePortfolio = { ...statePortfolio.portfolio };
    delete updatePortfolio[statePortfolio.accountNameSel];
  
    if (Object.keys(updatePortfolio).length > 0) {
      const firstAccountName = Object.keys(updatePortfolio)[0];
      dispatchPortfolio({
        portfolio: updatePortfolio,
        accountNameSel: firstAccountName,
        portfolioSel: updatePortfolio[firstAccountName],
        accountNames: Object.keys(updatePortfolio)
      });
    } else {
      dispatchPortfolio({
        portfolio: undefined,
        portfolioSel: [],
        accountNames: [],
        accountNameSel: undefined,
        portfolioOverview: [],
        portfolioTable: []
      });
    }
  }
  
  return (
    <CrudModal
    deleteSettings
    onClose={()=>dispatchPortfolio({accountSettings:false})}
    title={"Portfolio Account"}
    modalType='settings'
    placeholder={"enter a portfolio account name"}
    handleDelete={handleDeleteAccount}
    handleCreate={handleCreateAccount}
    />
  )
}

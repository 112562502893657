import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import ContactModal from "./pages/landing/ContactModal";
import { useAppInfo } from "AppState";
import AuthModal from "auth/AuthModal";
import AppLayoutLanding from "AppLayoutLanding";
import AppLayoutDashboard from './AppLayoutDashboard';
import useFindOnboarding from "data/user/onBoarding/useFindOnboarding";
import TSMainLoading from "components/Loading/TSMainLoading";
import UserTour from "onBoarding/UserTour";
import OnBoarding from "onBoarding/OnBoarding";
import useTickerInfo from "data/ticker/useTickerInfo";


export default function AppLayout() {
  const { stateApp } = useAppInfo();
  const location = useLocation();
  const pageName = location.pathname.split("/")[1];
  const {onboarding, initialized} = stateApp
  const {setDisplayName,showAround} = onboarding
  useFindOnboarding();
  useTickerInfo()
  useLayoutEffect(() => {
    const scrollToTop = () => {
      document.body.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
    };
    scrollToTop();
  }, [pageName]);

  if (!initialized && stateApp.isLoggedIn) {
    return <TSMainLoading />;
  }


  return (
    <div id="appLayout">
      {!stateApp.isLoggedIn && <AppLayoutLanding />}

      {stateApp.isLoggedIn && (
        <>
          {!setDisplayName && <OnBoarding />}
          {setDisplayName && !showAround && <UserTour />}
          {setDisplayName && <AppLayoutDashboard />}
        </>
      )}

      <ContactModal />
      <AuthModal />
    </div>
  );
}

import React, { useEffect, useState } from 'react';

const SidewayImageCollage = ({ images }) => {
  const [glowIndex, setGlowIndex] = useState(null);
  const [remainingIndices, setRemainingIndices] = useState([...Array(images.length).keys()]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (remainingIndices.length === 0) {
  //       // Reset remainingIndices when all have been covered
  //       setRemainingIndices([...Array(images.length).keys()]);
  //     } else {
  //       // Pick a random index from remainingIndices
  //       const randomIdx = Math.floor(Math.random() * remainingIndices.length);
  //       const newGlowIndex = remainingIndices[randomIdx];
        
  //       setGlowIndex(newGlowIndex);

  //       // Remove the used index from remainingIndices
  //       setRemainingIndices((prev) => prev.filter((_, i) => i !== randomIdx));
  //     }
  //   }, 1500); // Change glow every 1.5 seconds

  //   return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, [images.length, remainingIndices]);

  return (
    <div style={styles.gridContainer}>
      {images.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`Image ${index + 1}`}
          // className={glowIndex === index ? 'glowEffect' : ''}
          style={styles.image}
        />
      ))}
    </div>
  );
};

const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    gap: '5px',
    gridAutoFlow: 'dense',
    padding: '5px',
    width: '100%',
    boxSizing: 'border-box',
    background: 'black',
  },
  image: {
    width: '100%',
    height: '300px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    cursor: 'pointer',
    objectFit: 'cover',
  },
};

export default SidewayImageCollage;


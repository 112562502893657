import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GetStartedButton from './product/GetStartedButton';
import SidewayImageCollage from 'components/Media/SidewayImageCollage';
import FadeInText from './FadeInText';
import FadeIn from './FadeIn';
import TypewriterText from './TypewriterText';
import FadeBackground from './FadeBackground';
import BackgroundVideo from 'components/Media/BackgroundVideo';

const images = {
  Student: 'https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2380&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'Young Professional': 'https://plus.unsplash.com/premium_photo-1661774433750-b5275770d4ba?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'Retired':'https://images.unsplash.com/photo-1464998857633-50e59fbf2fe6?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'Professional': 'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'Professional Trader':'https://plus.unsplash.com/premium_photo-1661371241897-3202947ace30?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
}
const useCases = [
  {
    personality: 'Starting your financial journey?',
    image: 'https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2380&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Learn About Investing',
      'Save for Goals Like Travelling',
      'Track Your Spending Habits',
      'Budget for Student Loans',
      'Receive Guidance',
    ],
  },
  {
    personality: 'Starting a family?',
    image: 'https://plus.unsplash.com/premium_photo-1661774433750-b5275770d4ba?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Get a Financial Health Check',
      'Build an Investment Portfolio',
      'Budget Expenses',
      'Create a Plan for House Purchase',
      'Connect with a Professional',
      'Receive Guidance',
    ],
  },
  {
    personality: 'Planning for Retirement?',
    image: 'https://images.unsplash.com/photo-1464998857633-50e59fbf2fe6?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Simulate Your Retirement',
      'See How Much You Need to Retire',
      'Optimize Tax-Saving Investments',
      'Find Investment Opportunities Aligned with Portfolio Strategy',
      'Connect with a Professional',
    ],
  },
  {
    personality: 'Are you a Professional Investor?',
    image: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Manage Your Portfolio & Risks',
      'Find Investment Opportunities',
      'Analyze Markets & Economy',
      'Perform Fundamental & Quantitative Analysis',
      'Build Your Own Valuation Models',
    ],
  },
  {
    personality: 'Are you a Professional Trader?',
    image: 'https://plus.unsplash.com/premium_photo-1661371241897-3202947ace30?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Get Real-Time Market Alerts',
      'Find Trading Opportunities',
      'Perform Technical Analysis & Backtesting',
      'Execute Trades',
      'Manage Your Risks',
      'Screen Multi-Asset Classes',
    ],
  },
];


const UseCases = () => {
  const [visibleSection, setVisibleSection] = useState(0);
  const sectionsRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionsRef.current.indexOf(entry.target);
          setVisibleSection(index);
        }
      });
    }, options);

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <>
    <div style={{position:"relative",width:"100%",height:"auto",overflow:"hidden"}}>
    <div
  style={{
    // background: "rgba(0,0,0,0.25)",
    // backdropFilter: "blur(2px)",
    // WebkitBackdropFilter: "blur(2px)",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing:"border-box",
  }}
>
  
  <
  >
    <FadeBackground
    >
      <div
        style={{
          padding: "1rem",
          textAlign: "center",
        }}
      > 
       
        <FadeIn>
        <h1 style={{ color: "white", fontSize: "7rem", fontWeight: "900"}}>
          AI for Your Financial Journey
        </h1>
        </FadeIn>
       
        <FadeInText 
        style={{ fontSize: "4rem" }}
        className="gradient_text">
        Learn. Apply. Manage. Adapt. Grow.
          </FadeInText>
        <h1
          className="gradient_text fade-in"
          style={{ fontSize: "4rem", color: "var(--selectBlue)" }}
        >
        
        </h1>
      </div>
      <div
        className="fade-in"
        style={{paddingTop:"2rem"
        }}
      >
        <h1 style={{ color: "white",maxWidth:"50vw",textAlign:"center"}}>
        {/* AI that understands you. */}
          {/* Wherever you are in your financial journey, receive personalized
          guidance and the next best steps to grow financially. */}
        </h1>
        <br />
        {/* <h1 style={{ color: "var(--selectBlue)",maxWidth:"50vw",textAlign:"center"}}>
          You can either ask AI or do it yourself following our interactive
          guides.
        </h1> */}
      </div>
    </FadeBackground>
  </>
</div>
      <>
    <SidewayImageCollage images=
    {[images.Student,images['Young Professional'],images['Retired'],
,"https://images.unsplash.com/photo-1502323777036-f29e3972d82f?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
"https://plus.unsplash.com/premium_photo-1681912592428-5c685a41f31b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG1vdGhlcnxlbnwwfHwwfHx8MA%3D%3D",
  "https://plus.unsplash.com/premium_photo-1661475916373-5aaaeb4a5393?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZmFtaWx5fGVufDB8fDB8fHww",
    ,"https://images.unsplash.com/photo-1508243529287-e21914733111?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmVzc2lvbmFsfGVufDB8fDB8fHww",
    "https://images.unsplash.com/photo-1573496130141-209d200cebd8?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fHByb2Zlc3Npb25hbHxlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1464582883107-8adf2dca8a9f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzh8fHNtaWxpbmd8ZW58MHx8MHx8fDA%3D",
    ]}/>   
    </>
    </div>   

    <Box 
    sx={{ padding: '4rem 8rem', display: 'flex', width: "100%", boxSizing: "border-box", 
    gap: '2rem', justifyContent: "center", alignItems: "flex-start",position:"relative"}}>

      {/* User's Box */}
      
      <div  
      style={{position:"absolute",width:"100%",height:"100%",top:0,left:0,background:"black"}}>
      <BackgroundVideo/>
       </div>
        
      <Box
  sx={{
    // backgroundColor: 'black',
    borderRadius: '10px',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'sticky',
    top: '100px',
    flex: "0 0 400px",
    // height: '500px',
  }}
> 

<img
    src={useCases[visibleSection].image}
    alt={useCases[visibleSection].personality}
    className='fade-in'
    style={{    
      height:"500px",  // Allows image to take the available space dynamically
      width: "100%",     // Make sure it fills the width of the container
      objectFit: "cover",// Ensure the image covers the box without distortion
      borderRadius: '10px',
      // border:"1px solid blue"
    }}
  />
  <div style={{zIndex:3,padding:"1rem",display:"flex",flexDirection:"column"
    ,alignItems:"center",justifyContent:"center",width:"100%",boxSizing:"border-box"}}>
  <GetStartedButton sx={{borderRadius:"25px",marginTop:"1rem",padding:"0.5rem 1rem"}} icon={false}/>
  </div>

</Box>

      {/* Features */}
      <Box sx={{ flex: 1,padding:"2rem",position:"relative"}}>

        {useCases.map((useCase, index) => (
          <Box
            key={index}
            ref={(el) => (sectionsRef.current[index] = el)}
            sx={{
              opacity: visibleSection === index ? 1 : 0.5,
              transform: visibleSection === index ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
              minHeight: '80vh',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
              borderRadius:"4px",
              justifyContent: 'flex-start', // Ensures that the text is vertically centered
            }}
          > 
          <Typography className='gradient_text' style={{color:"white",padding:"2rem 0",fontWeight:"900"}} variant="h2">
          {useCase.personality}
                </Typography>
           
            <Box sx={{ textAlign: 'left',display:"flex",flexDirection:"column",gap:"1.5rem"
              ,borderRadius:"10px"}}>
              {useCase.features.map((feature, featureIndex) => (
                <FadeIn key={featureIndex} style={{display:"flex",gap:"2rem",alignItems:"center",color:"white"}}>
                <CheckCircleIcon sx={{color:"green"}}/>
                <Typography style={{color:"white"}} variant="h4">
                {feature}
              </Typography>
              </FadeIn>
               
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
    </>
  );
};

export default UseCases;

// FinanceProfessional.js
import React, { useState } from 'react';
import { professionalCategory } from './info';
import FeesDisplay from './Fees/FeesDisplay';
import FinanceProfessionalBanner from './FinanceProfessionalBanner';
import FinanceProfessionalForm from './FinanceProfessionalForm';
import { Link, useLocation, useParams } from 'react-router-dom';
import FinanceProfessionalExplore from './FinanceProfessionalExplore';
import FinanceProfessionalChat from './Chat/FinanceProfessionalChat';
import FinanceProfessionalHome from './FinanceProfessionalHome';
import Modal from 'components/Modal/Modal';
import { Alert } from '@mui/material';

const FinanceProfessional = () => {
  const location = useLocation()
  const { idProfessional,idProfessionalType} = useParams();
  const [modalDisclaimer,setModalDisclaimer] = useState(true)
  return (
    <>
    {/* {(!idProfessional && !idProfessionalType && idProfessionalType!=="form") && 
      <FinanceProfessionalHome/>
      } */}
    <>
    {(!idProfessional && idProfessionalType!=="form") && 
    <FinanceProfessionalExplore/>}
    {idProfessional &&    
    <> 
    <div style={{display:"flex",flexDirection:"column",height:"100%",overflow:"auto",}}>
    <div style={{padding:"0 1rem"}}>
    <Link to={`/AskProfessional`}>Professional</Link> / <Link to={`/AskProfessional/${idProfessionalType}`}>{idProfessionalType}</Link>
    </div>
    <div style={{display:"flex",gap:"1rem",padding:"0 0.5rem",flexWrap:"wrap"
      ,width:"100%",boxSizing:"border-box",position:"relative",flex:1}}>
    <div style={{flex:1,minWidth:"600px"}}>
    <FinanceProfessionalBanner/>
    </div>
    <div style={{minWidth:"400px"}}>
    <FeesDisplay/>
    </div>
    
    </div>
    
    <div style={{position:"sticky",left:"0px",bottom:"0px",zIndex:"1000",background:"white",}}>
    <div style={{padding:"1rem",display:"flex",alignItems:"center"
      ,justifyContent:"center",background:"white",zIndex:"1000"}}>
    <FinanceProfessionalChat/>
    </div>
    </div>
    </div></>}
    
    {(!idProfessional && idProfessionalType && idProfessionalType ==="form") &&
    <FinanceProfessionalForm/>}

    {/* {modalDisclaimer && <Modal 
    width='auto'
    height='auto'
    onClose={()=>setModalDisclaimer(false)}>
      <div style={{maxWidth:"400px",maxHeight:"300px",overflow:"auto"}}>
        
        <Alert severity='warning'>
        <h2>Disclaimer: Demo Layout</h2>
          This is just a demo layout of how interacting with professionals could look like.
        </Alert></div>
      </Modal>} */}
   
</>
</>
  );
};



export default FinanceProfessional;

// Sample Data

import React, {
  useEffect,
  useState,
} from 'react';

import SelectBox from 'components/Inputs/SelectBox';
import SelectMarket from 'components/Inputs/SelectMarket';
import TabLine from 'components/Tabs/TabLine';
import { Paper } from '@mui/material';
import { useAppInfo } from 'AppState';
import { assetMarket } from 'content/constants';
import {
  bonds,
  commodities,
  commodityDict,
  cryptoAll,
  etfMain,
  intermarket,
  marketList,
} from 'content/contentMarket';

import PageHeaderIcons from 'nav/PageHeaderIcons';
import { currencyCategory } from 'analytics/ticker/forex/forexKey';
import IndexStock from './IndexStock';
import MarketInfoTemplate from './MarketInfoTemplate';
import { menuMarket } from 'nav/menu';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';
import LeftMenuCheckboxList from 'sidebar/LeftSidebar/LeftMenuCheckboxList';

export default function MarketInfo() {
  const { stateTicker, stateMarket, dispatchMarket } = useAppInfo();
  const {assetClass,assetClassSel,menuSel,contentSel,commodityCategorySel,currencyCategorySel,} = stateMarket;
  const {tickerType,tickerSelInfo} = stateTicker 
  const [currencyBase, setCurrencyBase] = useState("USD");
  const [quoteType, setQuoteType] = useState("Direct");
  const [stockView,setStockView] = useState("Index Overview")

  const [menuOption, setMenuOption] = useState(defaultTabList);
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    const infoSel = menuMarket.filter((obj) => obj.name === menuSel);
    if (contentSel !== "search") {
      setContentList(infoSel[0]?.content);
    }
  }, [menuSel]);

  function handleMenuSel(v) {
    dispatchMarket({"menuSel": v });
    const infoSel = menuMarket
      .filter((obj) => obj.name === v)
      .map((obj) => obj.content);
    if (infoSel[0].length > 0) {
      dispatchMarket({"contentSel":infoSel[0][0] });
    } else {
      dispatchMarket({"contentSel": v });
    }
  }


  useEffect(() => {
    let assetInfoSel = assetInfo[assetClassSel];

    if (!["Commodities", "Forex", "Today"].includes(assetClassSel)) {
      dispatchMarket({
        tickerList: assetInfoSel?.tickerList,
        tickerDict: assetInfoSel?.tickerDict,
        tickerSelected: assetInfoSel?.tickerList
    });
    } else if (assetClassSel === "Commodities") {
      dispatchMarket({
        tickerDict: commodityDict[commodityCategorySel],
        tickerList: Object.keys(commodityDict[commodityCategorySel]),
        tickerSelected: Object.keys(commodityDict[commodityCategorySel])
    });
    } else if (assetClassSel === "Forex") {
      let tickerDict = {};
      let tickerList = [...currencyCategory[currencyCategorySel]];

      if (currencyBase && tickerList.includes(currencyBase)) {
        tickerList = tickerList.filter((currency) => currency !== currencyBase);
      }

      if (currencyBase !== "USD" && quoteType === "Direct") {
        tickerList.forEach((currency) => {
          const ticker = currencyBase + currency + "=X";
          tickerDict[ticker] = currency + "=X";
        });
      } else if (quoteType === "Indirect") {
        tickerList.forEach((currency) => {
          const ticker = currency + currencyBase + "=X";
          tickerDict[ticker] = currency + currencyBase + "=X";
        });
      } else {
        tickerList.forEach((currency) => {
          const ticker = currency + "=X";
          tickerDict[ticker] = currency + "=X";
        });
      }

      tickerList = Object.keys(tickerDict);

      dispatchMarket({
        tickerDict: tickerDict,
        tickerList: tickerList,
        tickerSelected: tickerList
    });
    }

    if (assetClassSel !== "Today") {
      setMenuOption(assetInfoSel?.menuOptions);
    }

    if (
      !assetInfoSel?.menuOptions.includes(menuSel) &&
      assetClassSel !== "Today"
    ) {
      handleMenuSel(assetInfoSel?.menuOptions[0]);
    } else if (assetClassSel === "Today") {
      handleMenuSel("Market Today");
    }
  }, [
    assetClassSel,
    commodityCategorySel,
    currencyBase,
    quoteType,
    currencyCategorySel,
  ]);

  function handleAssetClass(v) {
    dispatchMarket({"assetClassSel": v });
  }
  function handleCommodity(v) {
    dispatchMarket({"commodityCategorySel": v });
  }
  function handleForex(v) {
    dispatchMarket({"currencyCategorySel": v });
  }

  const content = (
    <MarketInfoTemplate info={contentSel}/>
  );


  function handleActiveTicker(){
    dispatchMarket({"assetClassSel":assetMarket?.[tickerType]})
    
  }
  return (
    <div className="containerColumnFlex">
        <div className="page_header_box">
        <div className="page_header_title_box">
          <SelectMarket />
          <h2>Market</h2>
          </div>
          <div className='page_header_content_box'>
            <SelectBox
              sx={{flex: 1 }}
              variant="outlined"
              width="200px"
              label={`Content`}
              options={assetClass}
              value={assetClassSel}
              onChange={(e, v) => handleAssetClass(v)}
            />
            {assetClassSel === "Stocks" && (
            <SelectBox
            sx={{ fontSize: "1rem", flex: 1 }}
            variant="outlined"
            width="200px"
            label={`Market ${assetClassSel} Info`}
            options={["Index Overview","Constituents","Heatmap","Scatter","Market Breadth"]}
            value={stockView}
            onChange={(e, v) => setStockView(v)}
          />
          )}

          {assetClassSel === "Commodities" && (
              <SelectBox
                sx={{ fontSize: "1rem", flex: 1 }}
                variant="outlined"
                width="200px"
                label={`Market ${assetClassSel} Info`}
                options={Object.keys(commodityDict)}
                value={commodityCategorySel}
                onChange={(e, v) => handleCommodity(v)}
              />
          )}
          {assetClassSel === "Forex" && (
                <SelectBox
                sx={{ fontSize: "1rem", flex: 1 }}
                variant="outlined"
                width="200px"
                label={"Region"}
                options={Object.keys(currencyCategory)}
                value={currencyCategorySel}
                onChange={(e, v) => handleForex(v)}
              />
          )}
          </div>


        <div className='page_header_toolkit'>
        <PageHeaderIcons onClick={handleActiveTicker} name="Active Ticker" title={"See Market of Active Ticker"}>
          </PageHeaderIcons>
        {/* <PageHeaderIcons name="Guide" guideType={"Market"}/> */}
        </div>

        </div>


      <>
  {assetClassSel === "Stocks" && (
    stockView !== "Index Overview" ? (
      <IndexStock info={stockView}/>
    ) : (
      stockView === "Index Overview" && (
        <CommonDisplay contentSel={contentSel}
         menuOption={menuOption} handleMenuSel={handleMenuSel}>
          {content}
          </CommonDisplay>
      )
    )
  )}
  
  {assetClassSel !== "Today" && assetClassSel !== "Stocks" && ( 
  <CommonDisplay contentSel={contentSel}
         menuOption={menuOption} handleMenuSel={handleMenuSel}>
          {content}
          </CommonDisplay>)}
  
  {assetClassSel === "Today" && (
    <div style={{ padding: "0.5rem", width: "100%", height: "100%", boxSizing: "border-box" }}>
      <Paper>
        {content}
      </Paper>
    </div>
  )}
</>

      {assetClassSel === "Forex" && (!["Exchange Rate"].includes(contentSel)) && (
        <div
          style={{
            display: "flex",
            gap: "1rem",
            borderTop: "1px solid #ddd",
            marginBottom: "0.5rem",
            alignItems: "center",
            boxSizing:"border-box"
          }}
        >
          <h3>Currency Setting: </h3>

          <SelectBox
            width="200px"
            label={"Currency Base"}
            options={["USD", "EUR"]}
            value={currencyBase}
            onChange={(e, v) => setCurrencyBase(v)}
          />
          <SelectBox
           width="200px"
            label={"Quote Type"}
            options={["Direct", "Indirect"]}
            value={quoteType}
            onChange={(e, v) => setQuoteType(v)}
          />
        </div>
      )}
    </div>
  );
}

function CommonDisplay({menuOption,contentSel,handleMenuSel,children}){
  const {dispatchMarket,stateMarket} = useAppInfo()
  const {tickerList,tickerSelected,tickerDict} = stateMarket;


  const handleTabRowChange = (event) => {
    const checkedVal = event.target.value;
    let tickerSelectedUpdate = tickerSelected
    if (tickerSelected.includes(checkedVal)){
        tickerSelectedUpdate = tickerSelected.filter(ticker=>ticker!=checkedVal)
    }
    else{
        tickerSelectedUpdate = [...tickerSelected,checkedVal]
    }
    dispatchMarket({"tickerSelected":tickerSelectedUpdate})
  
  };
  return (    
  <div className="containerLeftMenuAndContent">
  <LeftSidebarFloating header={"Ticker List"}>
    <LeftMenuCheckboxList checkList={tickerList} labelDict={tickerDict} 
    selectedList={tickerSelected} handleCheckbox={handleTabRowChange}/>
  </LeftSidebarFloating>
  <Paper className='containerMainContent'>
      <TabLine tabList={menuOption} tabIndex={menuOption.indexOf(contentSel)} handleTabChange={i => handleMenuSel(menuOption[i])} />
      <div className='containerColumnFlex1'>
        {children}
      </div>
    </Paper>
</div>)
}

const defaultTabList = [
  "Market Today",
  "Trend",
  "Technicals",
  "Heatmap",
  "Yield Curve",
  "Exchange Rate",
];

const assetInfo = {
  All: {
    tickerList: Object.keys(intermarket),
    tickerDict: intermarket,
    menuOptions: [
      "Trend Chart",
      "Trend Table",
      "Performance",
      "Volatility",
      "Correlation",
      "Indicators",
      "Beta",
      "Yield Curve",
      "Exchange Rate",
    ],
  },
  Stocks: {
    tickerList: Object.keys(marketList.Stocks),
    tickerDict: marketList.Stocks,
    menuOptions: [
      "Trend Chart",
      "Trend Table",
      "Performance",
      "Volatility",
      "Correlation",
      "Indicators",
      "Beta",
    ],
  },
  Crypto: {
    tickerList: Object.keys(cryptoAll),
    tickerDict: cryptoAll,
    menuOptions: [
      "Trend Chart",
      "Trend Table",
      "Performance",
      "Volatility",
      "Correlation",
      "Indicators",
      "Beta",
    ],
  },
  "ETF & MF": {
    tickerList: Object.keys(etfMain),
    tickerDict: etfMain,
    menuOptions: [
      "Trend Chart",
      "Trend Table",
      "Performance",
      "Volatility",
      "Correlation",
      "Indicators",
      "Beta",
    ],
  },
  "Fixed Income": {
    tickerList: Object.keys(bonds),
    tickerDict: bonds,
    menuOptions: [
      "Trend Chart",
      "Trend Table",
      "Performance",
      "Volatility",
      "Correlation",
      "Indicators",
      "Beta",
      "Yield Curve",
    ],
  },
  Commodities: {
    tickerCategory: commodityDict,
    tickerList: Object.keys(commodities),
    tickerDict: commodities,
    menuOptions: [
      "Trend Chart",
      "Trend Table",
      "Performance",
      "Volatility",
      "Correlation",
      "Indicators",
      "Beta",
    ],
  },
  Forex: {
    tickerList: Object.keys(marketList.Stocks),
    tickerDict: marketList.Stocks,
    menuOptions: [
      "Trend Chart",
      "Trend Table",
      "Performance",
      "Volatility",
      "Correlation",
      "Indicators",
      "Beta",
      "Exchange Rate",
    ],
  },
};

import React, { useEffect, useState } from 'react'
import SelectBox from 'components/Inputs/SelectBox'
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import { useAppInfo } from 'AppState';
import { assetCode, dfin } from 'content/constants';
import DatePicker from 'components/Inputs/DatePicker';
import TextInput from 'components/Inputs/TextInput';
import SearchTickerDropdown from 'search/SearchTickerDropdown';
import { tr } from 'date-fns/locale';


const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Get yesterday's date
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export default function AddTransaction({handleTransactionDetails,transactionDetails}) {
  const {statePortfolio} = useAppInfo()
  const [tickerSelected,setTickerSelected]  = useState()
  const {close} = useMultiTickerInfo([tickerSelected])
  const [date, setDate] = useState(formatDate(yesterday));
  const [price,setPrice] = useState()

  useEffect(() => {
    if(transactionDetails?.TICKER){
      setTickerSelected(transactionDetails?.TICKER)
    }

  },[transactionDetails])


  function handleTicker(obj){
    handleTransactionDetails({...transactionDetails,tickerError:false,...obj})
  }
  useEffect(() => {
    if (close.length > 0) {
      const findDate = close.filter((obj) => obj.Date === date);
  
      if (findDate.length > 0) {
        const priceOnDate = findDate[0][tickerSelected];
        setPrice(priceOnDate.toFixed(2));
      } else {
        // If findDate is empty, find the closest date and get the corresponding price
        const closestDate = close.reduce((prev, curr) => {
          const prevDiff = Math.abs(new Date(prev.Date) - new Date(date));
          const currDiff = Math.abs(new Date(curr.Date) - new Date(date));
          return prevDiff < currDiff ? prev : curr;
        });
  
        setPrice(closestDate[tickerSelected].toFixed(2));
      }
    }
  }, [close,date]);


  function handleDate(v) {
    handleTransactionDetails({...transactionDetails,date:v})
    setDate(v);
  }

  function handleQuantityChange(v) {
    handleTransactionDetails({...transactionDetails,quantity:v})
  }


  function handlePriceChange(v) {
    handleTransactionDetails({...transactionDetails,price:v})
  }


  const investmentTypeOptions = Object.values(assetCode)
  return (
    <div style={{width:"100%",display:"flex",flexDirection:"column",padding:"0.5rem",gap:"0rem",boxSizing:"border-box"}}>
    <div style={{display:"flex",gap:"1rem",width:"100%",boxSizing:"border-box",alignItems:"center",justifyContent:"center"}}>
      <SelectBox width="15%" size='small' options={investmentTypeOptions} 
      value={transactionDetails?.investmentType || assetCode?.Stocks} 
      onChange={(e,v)=>handleTransactionDetails({...transactionDetails,investmentType:v})} label={"Investment Type"}/>
      <div style={{flex:1,minWidth:"200px"}}>
        <SearchTickerDropdown defaultSearchTerm={transactionDetails?.TICKER} searchBar='standard' handleTickerType='unique' tickerType={transactionDetails?.investmentType || assetCode?.Stocks} handleTicker={handleTicker}/>
        {/* <h5 style={{color:"red"}}>Select a right ticker</h5> */}
        </div>
      
      <SelectBox width='15%' size='small' options={["Buy","Sell"]} value={transactionDetails?.transactionType || "Buy"} 
      onChange={(e,v)=>handleTransactionDetails({...transactionDetails,transactionType:v})} label={"Transaction Type"}/>
      <DatePicker sx={{width:"15%"}} date={transactionDetails?.date || formatDate(yesterday)} handleDate={handleDate} weekends={false}/>
      <TextInput sx={{width:"15%"}}  label='Quantity' value={transactionDetails?.quantity} type='number' onChange={handleQuantityChange}/>
      <TextInput sx={{width:"15%"}}  label='Price' value={transactionDetails?.price || price} type='number' onChange={handlePriceChange}/>
  </div>
  <div>
  
  </div>
  </div>
  )
}

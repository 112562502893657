import useFindFinancials from 'data/user/financials/useFindFinancials';
import React, { useEffect } from 'react'
import useMyFinancialsDetails from './useMyFinancialsDetails';
import useAddFinancials from 'data/user/financials/useAddFinancials';
import { useAppInfo } from 'AppState';
import { initializeFinancials } from 'appState/initialStateMyFinancials';
import { fi } from 'date-fns/locale';

export default function useSetMyFinancials() {
    const {dispatchMyFinancials,stateMyFinancials} = useAppInfo()
    useFindFinancials()
    useMyFinancialsDetails()

    const {noDataFound,financialsLoaded} = stateMyFinancials
  
    const { addFinancials, error } = useAddFinancials();
    
    useEffect(() => {
      if (noDataFound && financialsLoaded) {
        const initialData = initializeFinancials;
        Object.entries(initialData).forEach(([name, value], index) => {
          dispatchMyFinancials({[name]:value});
        });
        Object.entries(initialData).forEach(([name,value],index)=>{
          setTimeout(()=>{addFinancials(value, name)},500*index);
        })
      }
    }, [noDataFound,financialsLoaded]);
  
}

import { fieldInvestmentPreference } from 'content/contentInvestmentPreference';
import { generateUniqueId } from 'utils/utilGeneral';


const date = new Date()


const defaultFieldAsset = [
  { id:generateUniqueId(),selected:true, name: "Savings Account",assetType:"Savings", type: "Savings", amount: 10000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, return: 2, volatility: 0,forecastPeriod:30,edit:true},
  { id:generateUniqueId(),selected:true, name: "Cash",assetType:"Savings", type: "Cash", amount: 2000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, return: 0, volatility: 0 ,forecastPeriod:30,edit:true},
  { id:generateUniqueId(),selected:true, name: "Checking Account",assetType:"Cash", type: "Cash", amount: 5000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, return: 0, volatility: 0 ,forecastPeriod:30,edit:true},
];

const defaultFieldAssetBlank = { id:generateUniqueId(), 
  selected:true,
  name: "",assetType:"Savings", type: "Savings", 
  amount: 10000, date, currentAllocation: 0, 
  targetAllocation: 0, differenceAllocation: 0, return: 2, volatility: 0,forecastPeriod:30,edit:true}



const assetTypes = ["Cash","Savings","Investment Portfolio","Real Estate","Hedge Fund","Private Equity","Insurance","Other"]

const defaultFieldLiability = [
  {id:generateUniqueId(), name: "Mortgage Balance", type:"Mortgage", amount: 100000, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0,interestRate:10,paybackPeriod:"Monthly",compounding:"Monthly",loanTerm:{y:25,m:0},date,edit:true},
  {id:generateUniqueId(), name: "Car Loans", type:"Car Loan", amount: 20000, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0,interestRate:10,paybackPeriod:"Monthly",compounding:"Monthly",loanTerm:{y:10,m:0},date,edit:true},
  {id:generateUniqueId(), name: "Credit Card Bills", type:"Credit Card", amount: 1000, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0,interestRate:20,paybackPeriod:"Monthly",compounding:"Daily",loanTerm:{y:30,m:0},date,edit:true},
];

const defaultFieldLiabilityBlank = {id:generateUniqueId(), name: "", type:"Mortgage", amount: 100000, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0,interestRate:10,paybackPeriod:"Monthly",compounding:"Monthly",loanTerm:{y:25,m:0},date,edit:true}


const compoundingOptions = ["Not Applicable","Daily","Weekly","Bi-Weekly","Semi-Monthly","Monthly","Quarterly","Semi-Annually","Annually"]
const paybackPeriodOptions = ["Daily","Weekly","Bi-Weekly","Semi-Monthly","Monthly","Quarterly","Semi-Annually","Annually"]
const liabilityTypes = ["Credit Card","Mortgage","Personal Loan","Car Loan","Others"]


const periodTypes = ["Daily","Weekly","One Time","Monthly", "Quarterly", "Semi-Annually","Annually"]



const goalTypes = ["Education", "Travel", "Retirement", "Home", "Other"]

const defaultFieldGoals = [
  { id:generateUniqueId(),priority:1, name: "Education", type: "Education",frequency:"periodic", period: "Annually", date, amount: 4000, growthRate: 2,forecastPeriod:5,edit:true},
  { id:generateUniqueId(),priority:2, name: "Travel", type: "Travel",frequency:"periodic", period: "Quarterly", date, amount: 2000, growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(),priority:3, name: "Retirement", type: "Retirement",frequency:"periodic", period: "Monthly", date, amount: 4000, growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(),priority:4, name: "Home", type: "Home",frequency:"periodic", period: "Monthly", date, amount: 2000, growthRate: 2,forecastPeriod:20,edit:true},
  { id:generateUniqueId(),priority:5, name: "Other", type: "Other",frequency:"periodic",period: "Monthly", date, amount: 200, growthRate: 2,forecastPeriod:10,edit:true}
];

const defaultFieldGoalBlank =  { id:generateUniqueId(),priority:1, name: "", type: "Other", period: "Monthly", date, amount: 200, growthRate: 2,forecastPeriod:10,edit:true}

const defaultFieldIncome = [
  { id:generateUniqueId(), name: "Income from Salary (after Tax)", type: "Periodic Income", amount: 6000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Other Income", type: "Periodic Income", amount: 1000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true}
];

const defaultFieldIncomeBlank ={ id:generateUniqueId(), name: "", type: "Periodic Income", amount: 6000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true}

const defaultFieldExpense = [
  { id:generateUniqueId(), name: "Rent/Mortgage", type: "Rent/Mortgage", amount: 1000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Other House Expense", type: "House", amount: 500, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Property Tax", type: "Tax", amount: 100, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Food", type: "Grocery/Restaurant", amount: 800, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Travel", type: "Travel", amount: 500, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Recreation", type: "Recreation/Hobby", amount: 500, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Healthcare", type: "Healthcare", amount: 300, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true},
  { id:generateUniqueId(), name: "Insurance", type: "Insurance", amount: 300, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true}
];

const expenseTypes = ["Rent/Mortgage","House","Tax","Grocery/Restaurant","Travel","Recreation/Hobby","Healthcare","Insurance"]
const defaultFieldExpenseBlank = { id:generateUniqueId(), name: "", type: "Rent/Mortgage", amount: 1000, date, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true}

const fieldInfrequent = [{ name: '', amount: 0, date: ''}]



const defaultFieldCashAllocation = [
  {id: generateUniqueId(), type: "Cash", description: "", allocation: 100},
  {id: generateUniqueId(), type: "Savings", description: "", allocation: 0},
  {id: generateUniqueId(), type: "Goals", description: "", allocation: 0},
  {id: generateUniqueId(), type: "Investment Portfolio", description: "", allocation: 0},
  {id: generateUniqueId(), type: "Insurance", description: "", allocation: 0},
  {id: generateUniqueId(), type: "Real Estate", description: "", allocation: 0},
  {id: generateUniqueId(), type: "Hedge Fund", description: "", allocation: 0},
  {id: generateUniqueId(), type: "Private Equity", description: "", allocation: 0},
  {id: generateUniqueId(), type: "Other", description: "", allocation: 0},
];

const profileCompletionItems = 
{assets:{link:"Networth",name:"Add Assets",completedName:"Assets Added"},
liabilities:{link:"Networth",name:"Liabilities",completedName:"Liabilities Added"},
portfolio:{link:"Portfolio",name:"Create New Investment Portfolio",completedName:"Investment Portfolio Created"}
,incomeMonthly:{link:"Cashflow",name:"Add Income",completedName:"Income Added"},
expenseMonthly:{link:"Cashflow",name:"Add Expense",completedName:"Expense Added"},
goalsPeriodic:{link:"Goals",name:"Add Goals",completedName:"Goals Added"},
netCashAllocate:{link:"Cashflow",name:"Allocate Cashflow",completedName:"Cashflow Allocated"},
investmentPreference:{link:"InvestmentPreferences",name:"Set Investment Preferences",completedName:"Investment Preference Set"}
,riskTolerance:{link:"RiskManagement",name:"Assess Risk Tolerance",completedName:"Risk Tolerance Assessed"}
}

const profileCompletionValue = {assets:false,liabilities:false,portfolio:false,incomeMonthly:false,
  expenseMonthly:false,goalsPeriodic:false,netCashAllocate:false,investmentPreference:false,riskTolerance:false}


const initialStateMyFinancials = {
  currency: { code: "USD", name: "United States Dollar", symbol: "$" },
  financialHealthDetails:[],
  financialHealthIssues:[],
  financialHealthScore:0,
  financials:{},
  assets:[],
  liabilities:[],
  incomeMonthly:[],
  expenseMonthly:[],
  goalsPeriodic:[],
  incomeAll:[],
  expenseAll:[],
  summaryStats:{},
  marketRegion:["USA"],
  primaryMarketRegion:"USA",
  investmentPreference:[...fieldInvestmentPreference],
  investmentPreferenceRiskTolerance:[],
  investmentPreferenceType:{type:"Manual",options:["Manual","RiskTolerance"]},
  investmentHorizon:10,
  investmentHorizonGoalBased:true,
  riskTolerance:{test:false,score:0},
  netCashAllocate:defaultFieldCashAllocation,
  cashflowDistribution:{},
  menu:[],
  subMenuSel:"",
  menuSel:"",
  mainMenuSel:"Profile",
  warnings:[],
  goalAnalytics:{},
  liabilityIndividualDetails:[],
  liabilityStats:{},
  onBoardingStep:-1,
  noDataFound:false,
  profileCompletion:profileCompletionValue,
  completionPercentage:0,
  newGoalChat:[],
  financialsLoaded:false
}

const initializeFinancials = {
  assets:defaultFieldAsset,
  liabilities:defaultFieldLiability,
  incomeMonthly:defaultFieldIncome,
  expenseMonthly:defaultFieldExpense,
  goalsPeriodic:defaultFieldGoals,
  profileCompletion:profileCompletionValue,
}

const defaultFieldBlank = {
  "Asset":defaultFieldAssetBlank,
  "Liability":defaultFieldLiabilityBlank,
  "Income":defaultFieldIncomeBlank,
  "Expense":defaultFieldExpenseBlank,
  "Goals":defaultFieldGoalBlank
}

export {
  assetTypes,
  compoundingOptions,
  defaultFieldAsset,
  defaultFieldBlank,
  defaultFieldExpense,
  defaultFieldGoals,
  defaultFieldIncome,
  defaultFieldLiability,
  fieldInfrequent,
  initializeFinancials,
  initialStateMyFinancials,
  expenseTypes,
  goalTypes,
  liabilityTypes,
  paybackPeriodOptions,
  periodTypes,
  profileCompletionItems,
};






import React from 'react'
import useAssistantTickerAnalyst from './useAssistantTickerAnalyst';
import useAssistantStockAanlyst from './useAssistantStockAanlyst';
import useAssistantNewsEvents from './useAssistantNewsEvents';
import useAssistantEconomy from './useAssistantEconomy';
import { useAppInfo } from 'AppState';
import useAssistantFinancialAdvisor from './useAssistantFinancialAdvisor';
import useAssistantPortfolioManager from './useAssistantPortfolioManager';
import useAssistantOpportunityFinder from './useAssistantOpportunityFinder';
import { openaiAPI } from 'appState/initialStateAssistant';
import useAssistantFinancialProduct from './useAssistantFinancialProduct';
import { getWebSearchTavily } from 'data/searchWeb/SearchTavily';

export default function useAssistantHandleRequest (){
    const {stateAssistant,dispatchAssistant} = useAppInfo()
    const {openai} = stateAssistant
    const  {GetMyGoals,GetMyFinancialHealth,GetMyFinancials
      ,GetMyAssets,GetMyLiabilities,GetRiskTolerance,GetMyCashflow,GetMyIncome,GetMyExpense,GetNewGoals} = useAssistantFinancialAdvisor()
    const {GetPortfolioSummary,GetPortfolioValue,GetPortfolioHoldings
      ,GetPortfolioAssetAllocation,GetPortfolioVsBenchmark,GetPortfolioCharts} = useAssistantPortfolioManager()
    const {GetFinancialProduct} = useAssistantFinancialProduct()
    const {GetEconOverview} = useAssistantEconomy()
    const {GetTickerTechnicals,GetTrendingTicker,GetTickerInfo,GetAllTicker,GetTickerProfile
      ,GetTickerVsPeers,GetTickerVsEconomy,GetTickerVsBenchmark,GetTickerBuySel} = useAssistantTickerAnalyst()
    const {GetStockFinancials,GetStockValuation,GetStatsVsIndustry,GetStockEarningTranscript} = useAssistantStockAanlyst()
    const {GetMarketNews} = useAssistantNewsEvents()
    const {GetPortfolioFit,GetInvestmentOpportunity,GetSectorSpecificOpportunity} = useAssistantOpportunityFinder()
    async function handleRequiresAction(message,data, runId, threadId, updateChatCallback) {
      try {
        // console.log("data",data)
        // Use Promise.all to wait for all async actions to resolve
        // const date = new Date()
        const toolOutputs = await Promise.all(
          data.required_action.submit_tool_outputs.tool_calls.map(async (toolCall) => {
            // console.log("ToolCall",toolCall.function.name)
            // dispatchAssistant({"loadingMessage":`Fetching ${formatFunctionName(toolCall.function.name)}...`});
            if (toolCall.function.name === "get_ticker_info") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const tickerWant = args?.ticker;
              const info = await GetTickerInfo({ tickerWant });
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info), 
              };
            } 

            else if  (toolCall.function.name === "get_web_search") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {query} = args;
              const info = await getWebSearchTavily(query,"general")
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_financial_product") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {product_type,country} = args;
              const info = await GetFinancialProduct({product_type,country,message})
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_stock_earning_transcript") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {ticker,year} = args;
              const info = await GetStockEarningTranscript({ticker,yearUser:year})
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_ticker_vs_peers") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {tickers,react_component} = args;
              const info = GetTickerVsPeers({tickers,component:react_component})
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if (toolCall.function.name === "get_ticker_buy_sell") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {ticker} = args;
              const info = await GetTickerBuySel({message,ticker})

              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }


            else if  (toolCall.function.name === "get_ticker_technicals") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const component = args?.react_component;
              const ticker = args?.ticker;
              const info = GetTickerTechnicals({component,ticker})

              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            

            else if (toolCall.function.name === "get_ticker_vs_industry") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const ticker = args?.ticker;
              const info = GetStatsVsIndustry({ticker})

              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info), 
              };
            } 
            else if (toolCall.function.name === "get_ticker_vs_economy") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const ticker = args?.ticker;
              const info = GetTickerVsEconomy({ticker})
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info), 
              };
            } 
            else if (toolCall.function.name === "get_ticker_vs_benchmark") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const ticker = args?.ticker;
              const info = GetTickerVsBenchmark({ticker})
  
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info), 
              };
            } 
            else if (toolCall.function.name === "get_ticker_profile") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const ticker = args?.ticker;
              const info = await GetTickerProfile({ticker})

              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info), 
              };
            } 


            else if (toolCall.function.name === "get_trending_ticker") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const tickerType = args?.tickerType;
              const stockFilter = args?.stockFilter;
              const info = GetTrendingTicker({tickerType,stockFilter})

              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if (toolCall.function.name === "get_industry_specific_opportunity") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {sector,industry} = args;
              const info = GetSectorSpecificOpportunity({sector,industry})
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if (toolCall.function.name === "get_portfolio_fit") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const assetType = args?.assetType;
             
              const info = GetPortfolioFit()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if (toolCall.function.name === "get_report") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const react_component = args?.react_component;
    
              const info = react_component ? {react_component} : {"react_component":"ReportPersonalFinance"}
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if (toolCall.function.name === "get_investing_opportunity") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const opportunityType = args?.opportunityType;       
              const info = GetInvestmentOpportunity()

              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_stock_valuation") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const ticker = args?.ticker
              const valuationType = args?.valuation_type
              const info = GetStockValuation({ticker,valuationType})

              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_stock_financials") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {financials,ticker,peers} = args;

              const info = GetStockFinancials({ticker,peers})
              
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_market_news") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {newsType} = args;
              const info = GetMarketNews({newsType})
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_econ_overview") {
              const info = await GetEconOverview()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_all_ticker_details") {
              const info = await GetAllTicker()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_my_financials") {
              const info = GetMyFinancials()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_my_financial_health") {
              const info = GetMyFinancialHealth()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_my_goals") {
              const info = GetMyGoals()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_new_goals") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const {react_component,
                goal_type,
                goal_name,
                amount,start_date,period_frequency,forecast_period,inflation} = args;

              const info = await GetNewGoals({message,react_component,goal_type,
                goal_name,amount,start_date,period_frequency,forecast_period,inflation})
          
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_my_assets") {
              const info = GetMyAssets()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
        

            else if  (toolCall.function.name === "get_my_liabilities") {
              const info = GetMyLiabilities()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
        
            else if  (toolCall.function.name === "get_my_risk_tolerance") {
              const info = GetRiskTolerance()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_my_cashflow") {
          
              const info = GetMyCashflow()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            
            else if  (toolCall.function.name === "get_my_expenses") {
          
              const info = GetMyExpense()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            
            else if  (toolCall.function.name === "get_my_income") {
          
              const info = GetMyIncome()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }


            else if (toolCall.function.name === "get_financial_projections") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const component = args?.graphical_component;
              const infoOptions = {"Goals":"GoalsProjection","Asset":"AssetProjection",
                "Liability":"LiabilityProjection","Cashflow":"CashflowProjection"}

                const filteredOptions = Object.keys(infoOptions)
  .filter(key => component.includes(key))  // Filter keys based on presence in the component array
  .reduce((acc, key) => {
    acc[key] = infoOptions[key];  // Build the filtered object
    return acc;
  }, {});
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(filteredOptions),
              };
            }

            // PortfolioManager
            else if  (toolCall.function.name === "get_portfolio_summary") {
              const info = GetPortfolioSummary()
              
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_portfolio_value") {
              const info = GetPortfolioValue()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_portfolio_asset_allocation") {
              const info = GetPortfolioAssetAllocation()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else if  (toolCall.function.name === "get_portfolio_holding") {
              const info = GetPortfolioHoldings()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }

            else if  (toolCall.function.name === "get_portfolio_benchmark") {
              const info = GetPortfolioVsBenchmark()
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
        
            else if (toolCall.function.name === "get_portfolio_chart") {
              const args = JSON.parse(toolCall?.function?.arguments);
              const component = args?.graphical_component;
              const info = GetPortfolioCharts({component})
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify(info),
              };
            }
            else {
              return {
                tool_call_id: toolCall.id,
                output: JSON.stringify("Couldn't find what you are looking for."),
              };
            }

          })
        );
        // Submit the tool outputs and pass the callback for chat updates
        await submitToolOutputs(toolOutputs, runId, threadId, updateChatCallback);
      } catch (error) {
         console.error("Error processing required action:", error);
        await cancelRun({ threadId, runId });
        updateChatCallback("Sorry, error processing - try again.");
       
        
      }
    }
    
  
    async function submitToolOutputs(toolOutputs, runId, threadId, updateChatCallback) {
      try {
        const stream = openai.beta.threads.runs.submitToolOutputsStream(
          threadId, runId, { tool_outputs: toolOutputs }
        );
    
        // Handle stream events after submitting tool outputs
        let toolResponse = "";
    
        for await (const event of stream) {
          // console.log("Event",event)
          if (event.event === "thread.run.queued") {
              dispatchAssistant({runId:event.data.id})
            }

          if (event.data?.delta?.content) {
            const toolEventContent = event.data?.delta?.content[0]?.text?.value || "";
            toolResponse += toolEventContent;
            // console.log("ToolResponse",toolResponse)
            // Update the chat progressively with tool output
            updateChatCallback(toolResponse);
          }
        }
    
      } catch (error) {
      await cancelRun({ threadId, runId });
      updateChatCallback("Something went wrong, please try again");
      
        console.error("Error submitting tool outputs:", error);
      }
    }
    
    return {handleRequiresAction}
}

async function cancelRun({threadId, runId}) {
  if (threadId && runId) {
    try {
      await openaiAPI.beta.threads.runs.cancel(threadId, runId);
      // console.log("Run canceled successfully");
    } catch (error) {
      console.log(error);
      // Do nothing if an error occurs
    }
  }
}

function formatFunctionName(name) {
  return name
    .replace(/^get_/, '')               // Remove "get_" at the start
    .split('_')                         // Split the string by underscores
    .map(word => word.charAt(0) + word.slice(1)) // Capitalize each word
    .join('');                          // Join them back together
}
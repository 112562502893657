import OpenAI from "openai";

export const openaiAPI = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI,
  dangerouslyAllowBrowser: true,
});

export const defaultMessageList = ['How am I doing financially?', 'How should I improve my finances?', 'Any opportunities for me today?','Learn about Stocks','Learn about Crypto',"Financial Health", 
  "Goals", "Portfolio Overview","Portfolio Value over time",
  "Portfolio Current Position","Portfolio Asset Allocation by Asset Class",
  "Portflio Target Allocation","Asset Details","Liability Details",
  "How do I meet my Goals","Opportunities that fit my portfolio",
  "Trending Opportunities","Undervalued stocks","Market News",
  "Market Report","Economy Report","Personal Finance Report","Portfolio Report",
  "Crypto News","Stock News","Forex News","General News","Financial Projections","Asset Projections","Goal Projections","Liability Projections","Cashflow Projection","Income Projection"];


export const assistants = {
    "FacilitatorOld":"asst_zs5JkWOXddpe315JkSBgOHMd",
    "Facilitator":"asst_17CibI7ACG3z7ba5jkzPpxxe",
    "FinancialPlanningOld":"asst_1hc56YnUHcrj46Ygss62a16f",
    "FinancialPlanningNew":"asst_IBnnrypVPRX5W4y2w8WsHR0m",
    "FinancialPlanning":"asst_0nuhAWd9MdcCetgIz3NF4rm6",
    "PortfolioManager":"asst_FaHB6J5m1I3qT3MwREK4ZfX1",
    "OpportunityFinder":"asst_AT33RoA5KBfhvSqRk21oVUK4",
    "TickerAnalystOld":"asst_vq7dkM4hYjqL3McwCzhG8fqC",
    "TickerAnalyst":"asst_rLTI7UvpNAzDAz0ZopOLML0K",
    "EconomyAnalyst":"asst_w4TkoJL1mIjjHNqLaTUkxvRz",
    "NewsAssistant":"asst_WyqpJLBWK3EQuxMcpbxLJzHI",
    "ReportBuilder":"asst_cGrShsrLSCH9rczPuFqvgsLa",
    "FinancialProductAnalyst":"asst_XbwnYcekNgxFy1DyDaWwE0FB",
    "GeneralAssistant":"asst_3lVAuflyjQiqPxlOQbngcVhi",
  }


  export const assistantDetails = [
    { assistant: "Supervisor", id: assistants.Facilitator, name: "Facilitator", image: "/path/to/supervisor-image.jpg" },
    { assistant: "FinancialPlanning", id: assistants.FinancialPlanning, name: "Financial Advisor", image: "/path/to/financial-planning-image.jpg" },
    { assistant: "PortfolioManager", id: "asst_FaHB6J5m1I3qT3MwREK4ZfX1", name: "Portfolio Manager", image: "/path/to/portfolio-manager-image.jpg" },
    { assistant: "OpportunityFinder", id: "asst_AT33RoA5KBfhvSqRk21oVUK4", name: "Trend Spotter", image: "/path/to/opportunity-finder-image.jpg" },
    { assistant: "TickerAnalyst", id: "asst_rLTI7UvpNAzDAz0ZopOLML0K", name: "Market Analyst", image: "/path/to/ticker-analyst-image.jpg" },
    { assistant: "EconomyAnalyst", id: "asst_w4TkoJL1mIjjHNqLaTUkxvRz", name: "Economy Analyst", image: "/path/to/economy-analyst-image.jpg" },
    { assistant: "NewsAssistant", id: "asst_WyqpJLBWK3EQuxMcpbxLJzHI", name: "News Specialist", image: "/path/to/market-news-image.jpg" },
    { assistant: "ReportBuilder", id: "asst_cGrShsrLSCH9rczPuFqvgsLa", name: "Reporting Analyst", image: "/path/to/general-assistant-image.jpg" },
    { assistant: "FinancialProductAnalyst", id: assistants.FinancialProductAnalyst, name: "Financial Product Analyst", image: "/path/to/general-assistant-image.jpg" },
    { assistant: "GeneralAssistant", id: "asst_3lVAuflyjQiqPxlOQbngcVhi", name: "Geek", image: "/path/to/general-assistant-image.jpg" }
  ];
  

export const initialStateAssistant = {
    assistantId:assistants.Facilitator,
    threadId:null,
    runId:null,
    chats:[],
    chatGptMessage:"",
    loading:false,
    loadingMessage:"",
    openai:openaiAPI,
    showContent:false,
    voiceRecording:false,
    chatStatus:"idle",
    userMode:"New",
    defaultQuestionList:[...defaultMessageList],
    chatHistory:[],
    questionHistory:[],
    componentProps:[],
    questionChat:[],
    chatWindow:true,
    exploreQuestions:false,
    exploreAssistants:false,
    assistantSelected:assistants.Facilitator
}
  
  

  
export const messagePlaceholders = ['Ask anything finance...', 'How am I doing financially?', 
  'How should I improve my finances?', 'Any opportunities for me today?', 
  'Am I beating the market?', 'How much am I burning monthly?','Am I meeting my Goals?'];
